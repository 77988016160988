.topicPage {
    --header-height: #{getRem(500)};
    z-index: 1000;
    position: relative;

    .resource-subheader {
        margin-left: getRem(-40);
    }

    main img {
        max-width: 100%;
        height: auto;
    }

    .titleBlock {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: getRem(75);
        padding-top: getRem(45);

        @include breakpoint(tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: getRem(50);
        }

        @include breakpoint(mobile) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        h1,
        h2,
        h3 {
            margin-top: 0px;
        }

        .title.h1 {
            margin-bottom: 0;
        }
    }
    header.topicHeader {
        --image-clip-size: #{getRem(10000)};
        min-height: var(--header-height);
        position: relative;
        display: block;
        align-items: flex-end;
        justify-content: flex-start;
        color: var(--white);
        @include breakpoint(tablet) {
            --header-height: #{getRem(400)};
        }
        @include breakpoint(mobile) {
            --header-height: #{getRem(100)};
            display: block;
        }
        .wrapper .half {
            background-color: var(--bg-color);
            color: var(--text-color);
            margin-left: 0;
            margin-right: auto;
            padding: 0;
            border-top-left-radius: getRem(8);
            border-top-right-radius: getRem(8);
            padding-top: 1rem;
            @include breakpoint(desktop) {
                max-width: 54%;
                margin-bottom: -100px;
                padding-left: 6.5rem;
            }
            @include breakpoint(tablet) {
                $padding: getRem(32);
                max-width: calc(50% + #{getRem(72)} + #{$padding});
                padding-left: $padding;
                padding-right: $padding;
                transform: translateX(-#{$padding});
            }
            @include breakpoint(tablet-desktop) {
                // display: none;
                --title-spacing: calc(var(--header-height) - 200px);
                display: block;
                margin-top: var(--title-spacing);
                position: relative;
                padding-top: 2rem;
                margin-bottom: 0.5rem;
            }
            @include breakpoint(mobile) {
                margin-bottom: 150px;
                transform: translateY(150px);
                p,
                h1,
                h2,
                h3,
                h4,
                h5 {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        .title-container {
            width: 100%;
            display: flex;
            align-items: center;
            //justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: getRem(48);
            padding-right: getRem(16);
        }

        h1 {
            margin: 0 getRem(32) 0 0;
        }

        .follow-btn {
            margin: getRem(16) 0;
        }

        .image {
            --mobile-height: #{getRem(150)};
            position: absolute;
            width: 100%;
            height: var(--header-height);
            display: block;
            top: 0;
            left: 0;
            z-index: -5;
            clip-path: circle(
                var(--image-clip-size) at 100%
                    calc(-1 * var(--image-clip-size) + 100%)
            );
            @include breakpoint(mobile) {
                height: var(--mobile-height);
            }
        }
    }
    .split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-top: 2rem;
        align-items: flex-start;
        @include breakpoint(mobile) {
            grid-template-columns: 1fr;
        }

        h2.caption {
            margin: 0 0 getRem(25) 0;
            padding: 0;
        }
        @include breakpoint(desktop) {
            main {
                padding-left: 6.5rem;
            }
        }
        aside {
            background-color: var(--light-grey);
            border-radius: getRem(6);
            @include breakpoint(desktop) {
                margin-left: getRem(50);
                padding: getRem(45) getRem(40);
            }
            @include breakpoint(mobile-tablet) {
                padding: getRem(27) getRem(25);
            }
            .accordion__button {
                padding-right: 2em;
            }
            @include breakpoint(mobile) {
                margin-bottom: 4rem;
            }
        }
    }

    .contact {
        margin: 2rem 0 6rem;
        padding: getRem(45) getRem(40);
        background-color: var(--light-grey);
        border-radius: getRem(6);

        @include breakpoint(mobile) {
            margin: 4rem 0 2rem 0;
            padding: getRem(27) getRem(25);
        }

        p {
            margin: 0 0 getRem(40) 0;

            &:last-of-type {
                margin: 0;
            }
        }

        .contact-name {
            display: block;
            @include font(18, 25);
            font-weight: bold;
            margin: 0;
        }

        .contact-title {
            display: block;
            @include font(14, 24);
            color: var(--grey);
            margin: 0;
        }
    }

    .posts {
        background-color: var(--light-grey);
        margin-bottom: 0;
        padding-bottom: 0;
        .resultsHeader {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: getRem(30);
        }
    }
    .pagination-bottom {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .side-contacts {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            padding: 0 0 getRem(40) 0;
            margin: 0;
        }
        li div,
        li div a {
            margin: 0;
            padding: 0;
            line-height: 1.5em;
        }
    }

    button.clearSearch {
        appearance: none;
        background-color: transparent;
        border: 0px solid transparent;
        color: var(--red);
        cursor: pointer;
        font-weight: bold;
        @include font(14, 24);
        &:hover {
            text-decoration: underline;
        }
    }

    // stamps
    .stamps {
        ul {
            margin: 0 0 0 getRem(10) !important;
            padding: 0 !important;
        }

        li {
            margin: 0 !important;
            padding: 0 !important;

            > div {
                margin: 0 0 0 getRem(10) !important;
            }
        }
    }

    .stamp-label {
        display: none !important;
    }

    .shamelessly-stolen,
    .creative-effectiveness {
        display: none;
    }

    //search
    .searchbox-label {
        @include font(14, 18);
        text-transform: uppercase;
        margin: 0 0 getRem(10) 0;
    }

    .search-term {
        font-weight: bold;
        @include font(28, 32);
        margin: 0 0 getRem(10) 0;
    }

    .search-results {
        @include font(21, 26);
        margin: 0;
    }

    .ais-SearchBox {
        position: relative;
        height: 50px;
    }

    .ais-SearchBox-submit {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .ais-SearchBox-submitIcon {
        width: 20px;
        height: 20px;
    }

    .ais-SearchBox-reset {
        position: absolute;
        bottom: -35px;
        right: 0;
        background-color: transparent;
        border: 0;
        appearance: none;

        &:hover {
            cursor: pointer;

            &::before {
                text-decoration: underline;
            }
        }

        &::before {
            content: "Clear Search";
            font-weight: bold;
            @include font(14, 24);
            color: var(--red);
        }
    }

    .ais-SearchBox-resetIcon {
        display: none;
    }

    .ais-InfiniteHits-list {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: getRem(40);
        align-items: flex-start;
        margin: 0;
        padding: 0 0 getRem(75) 0;
        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include breakpoint(mobile) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .ais-InfiniteHits-item {
        height: 100%;
        list-style-type: none;
    }

    .ais-InfiniteHits-loadMore {
        @extend .btn;
        left: 50%;
        margin: 0 0 getRem(50) 0 !important;
        transform: translateX(-50%);

        &--disabled {
            display: none;
        }
    }

    .iconImageContainer {
      min-height: getRem(156);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--yellow);

      &.happyMealContainer {
        background: #FFFFFF;
        border: 2px solid var(--yellow);
      }

      @include breakpoint(tablet) {
        min-height: 19vw;
      }

      @include breakpoint(mobile) {
        min-height: 48vw;
      }
    }
}
