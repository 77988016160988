@import "../global/mixins";
// using CSS variables
:root {
    --bg-color: white;
    --text-color: #000000;
    --grey: #777676;
    --text-light: var(--grey);
    --max-width: 1440px;
    --max-width-small: 1165px;
    --padding: 2rem;
    --shadow: 0 0 28px 0 rgba(0, 0, 0, 0.2);
    --shadow-nav: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
    --black: #000000;
    --white: #ffffff;
    --red: #db0007;
    --red-dark: #9f0106;
    --yellow: #ffbc0d;
    --blue: #006bae;
    --blue-dark: #00426c;
    --nav-height: 92px;
    --footer-background: #d6d6d6;
    --light-grey: #f7f7f7;
    // Layout
    --gap: #{getRem(40)};
    --narrow-gap: #{getRem(20)};
    --text-light2: #6f6f6f;
    --page-margin: #{getRem(122)};
    // Selection
    --selection-bg: var(--yellow);
    --selection-color: var(--black);
    --reaction-thumbsUp: #56afd1;
    --reaction-thumbsDown: #b69a81;
    --reaction-check: #ffbc0d;
    --reaction-question: #a9c141;
    --form-border: #979797;
    @include breakpoint(tablet) {
        --gap: #{getRem(18)};
        --max-width: 90vw;
        --max-width-small: 90vw;
        --nav-height: 60px;
        --page-margin: #{getRem(64)};
    }
    @include breakpoint(mobile) {
        --max-width: 90%;
        --nav-height: 60px;
        --page-margin: #{getRem(64)};
    }

    // Misc
    --image-placeholder-color: var(--footer-background);
    --image-placeholder: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJpbWFnZSIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWltYWdlIGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQ2NCA0NDhINDhjLTI2LjUxIDAtNDgtMjEuNDktNDgtNDhWMTEyYzAtMjYuNTEgMjEuNDktNDggNDgtNDhoNDE2YzI2LjUxIDAgNDggMjEuNDkgNDggNDh2Mjg4YzAgMjYuNTEtMjEuNDkgNDgtNDggNDh6TTExMiAxMjBjLTMwLjkyOCAwLTU2IDI1LjA3Mi01NiA1NnMyNS4wNzIgNTYgNTYgNTYgNTYtMjUuMDcyIDU2LTU2LTI1LjA3Mi01Ni01Ni01NnpNNjQgMzg0aDM4NFYyNzJsLTg3LjUxNS04Ny41MTVjLTQuNjg2LTQuNjg2LTEyLjI4NC00LjY4Ni0xNi45NzEgMEwyMDggMzIwbC01NS41MTUtNTUuNTE1Yy00LjY4Ni00LjY4Ni0xMi4yODQtNC42ODYtMTYuOTcxIDBMNjQgMzM2djQ4eiI+PC9wYXRoPjwvc3ZnPg==");
    --dropdown-indicator: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGlkPSJhIiBkPSJNMjAuMzczIDBsMjAgMjQuNjQ1aC00MHoiLz48L2RlZnM+PHVzZSBmaWxsPSIjRkZGIiB4bGluazpocmVmPSIjYSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
    --ui-minus: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCAyMiAzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPkxpbmUgMyBDb3B5IDM8L3RpdGxlPgogICAgPGcgaWQ9IlVzZXItRDMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJUb3BpYy1QYWdlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM1OS4wMDAwMDAsIC04ODEuMDAwMDAwKSIgZmlsbD0iIzI5MjkyOSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTI2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MjAuMDAwMDAwLCA2MzEuNTAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgMjQwLjQ1Nzc3MSkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik00OTksMTAuNTQyMjI5MiBMNDk5LDkuNzkyMjI5MTUgQzQ5OSw5LjY1NDE1Nzk2IDQ5OS4xMTE5MjksOS41NDIyMjkxNSA0OTkuMjUsOS41NDIyMjkxNSBMNTIwLDkuNTQyMjI5MTUgQzUyMC41NTIyODUsOS41NDIyMjkxNSA1MjEsOS45ODk5NDQ0IDUyMSwxMC41NDIyMjkyIEw1MjEsMTEuMjkyMjI5MiBDNTIxLDExLjQzMDMwMDMgNTIwLjg4ODA3MSwxMS41NDIyMjkyIDUyMC43NSwxMS41NDIyMjkyIEw1MDAsMTEuNTQyMjI5MiBDNDk5LjQ0NzcxNSwxMS41NDIyMjkyIDQ5OSwxMS4wOTQ1MTM5IDQ5OSwxMC41NDIyMjkyIFoiIGlkPSJMaW5lLTMtQ29weS0zIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
    --ui-plus: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjIgMjIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgNDwvdGl0bGU+CiAgICA8ZyBpZD0iVXNlci1EMyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGljbGUtRGV0YWlsLS0tVG9vbGJhci1PdmVybGF5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxNy4wMDAwMDAsIC00MjcuMDAwMDAwKSIgZmlsbD0iIzI5MjkyOSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwMTcuMDAwMDAwLCA0MjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMCwxMSBMMCwxMC4yNSBDLTEuNjkwODg0MzhlLTE3LDEwLjExMTkyODggMC4xMTE5Mjg4MTMsMTAgMC4yNSwxMCBMMjEsMTAgQzIxLjU1MjI4NDcsMTAgMjIsMTAuNDQ3NzE1MyAyMiwxMSBMMjIsMTEuNzUgQzIyLDExLjg4ODA3MTIgMjEuODg4MDcxMiwxMiAyMS43NSwxMiBMMSwxMiBDMC40NDc3MTUyNSwxMiA2Ljc2MzUzNzUxZS0xNywxMS41NTIyODQ3IDAsMTEgWiIgaWQ9IkxpbmUtMy1Db3B5LTMiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDExIEwwLDEwLjI1IEMtMS42OTA4ODQzOGUtMTcsMTAuMTExOTI4OCAwLjExMTkyODgxMywxMCAwLjI1LDEwIEwyMSwxMCBDMjEuNTUyMjg0NywxMCAyMiwxMC40NDc3MTUzIDIyLDExIEwyMiwxMS43NSBDMjIsMTEuODg4MDcxMiAyMS44ODgwNzEyLDEyIDIxLjc1LDEyIEwxLDEyIEMwLjQ0NzcxNTI1LDEyIDYuNzYzNTM3NTFlLTE3LDExLjU1MjI4NDcgMCwxMSBaIiBpZD0iTGluZS0zLUNvcHktNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCAxMS4wMDAwMDApIHJvdGF0ZSgtMjcwLjAwMDAwMCkgdHJhbnNsYXRlKC0xMS4wMDAwMDAsIC0xMS4wMDAwMDApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
    --ui-radio-unchecked: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyAyNiI+CiAgPHJlY3QgeT0iLjcxIiB3aWR0aD0iMjQuNTgiIGhlaWdodD0iMjQuNTgiIHJ4PSIxMi4yOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSkiIGZpbGw9IiNGRkYiIHN0cm9rZT0iIzI5MjkyOSIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=");
    --ui-radio-checked: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjdweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjcgMjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgMzwvdGl0bGU+CiAgICA8ZyBpZD0iU3R5bGUtR3VpZGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJEZXNrdG9wLVVJLUVsZW1lbnRzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTM1LjAwMDAwMCwgLTE2MjcuMDAwMDAwKSI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5MzYuNDA1Mjg3LCAxNjI3LjY2MzAyMykiPgogICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgc3Ryb2tlPSIjMjkyOTI5IiBmaWxsPSIjRkZGRkZGIiB4PSItMS43NzYzNTY4NGUtMTUiIHk9IjEuMTM2ODY4MzhlLTEzIiB3aWR0aD0iMjQuNTgwMDg4MiIgaGVpZ2h0PSIyNC41ODAwODgyIiByeD0iMTIuMjkwMDQ0MSI+PC9yZWN0PgogICAgICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgZmlsbD0iI0ZGQkMwRCIgY3g9IjEyLjI5MDA0NDEiIGN5PSIxMi4yOTAwNDQxIiByPSI5LjE4MDAyMjA1Ij48L2NpcmNsZT4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
    --ui-checkbox-checked: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjcgMjciPgogIDxkZWZzPgogICAgPHBhdGggaWQ9ImEiIGQ9Ik05LjUgMTUuNzVsLTQuMTctNC4xNy0xLjQyIDEuNDEgNS41OSA1LjU5IDEyLTEyLTEuNDEtMS40MXoiLz4KICA8L2RlZnM+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxyZWN0IHN0cm9rZT0iIzI5MjkyOSIgZmlsbD0iIzI5MjkyOSIgd2lkdGg9IjI0LjU4IiBoZWlnaHQ9IjI0LjU4IiByeD0iMiIvPgogICAgPHVzZSBmaWxsPSIjRkZCQzBEIiB4bGluazpocmVmPSIjYSIvPgogIDwvZz4KPC9zdmc+");
    --ui-checkbox-unchecked: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyAyNyI+CiAgPHJlY3QgeD0iOTYwIiB5PSIyMDgzIiB3aWR0aD0iMjQuNTgiIGhlaWdodD0iMjQuNTgiIHJ4PSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTU5IC0yMDgyKSIgZmlsbD0iI0ZGRiIgc3Ryb2tlPSIjMjkyOTI5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==");
    --ui-calendar: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOSAyMCI+CiAgPHBhdGggZD0iTTE2Ljg3MiAyaC0xVjBoLTJ2MmgtOFYwaC0ydjJoLTFjLTEuMTEgMC0xLjk5LjktMS45OSAybC0uMDEgMTRhMiAyIDAgMDAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWNGMwLTEuMS0uOS0yLTItMnptMCAxNmgtMTRWN2gxNHYxMXptLTEyLTloNXY1aC01Vjl6IiBmaWxsPSIjNDQ0IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+");
    --ui-select: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxNyI+CiAgPGcgZmlsbD0iY3VycmVudENvbG9yIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgPHBhdGggZD0iTTEuNDE2IDcuNzRsLjUzLS41MyA4LjE1NSA4LjE1NS0xLjIzNyAxLjIzOC03LjQ0OC03LjQ0OGExIDEgMCAwMTAtMS40MTV6Ii8+CiAgICA8cGF0aCBkPSJNMTUuNzgyIDcuMjFsMS4yMzcgMS4yMzgtOC4xNTUgOC4xNTUtLjUzLS41M2ExIDEgMCAwMTAtMS40MTVsNy40NDgtNy40NDh6Ii8+CiAgPC9nPgo8L3N2Zz4=");
    --ui-search: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIxOXB4IiB2aWV3Qm94PSIwIDAgMTkgMTkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CiAgICA8ZyBpZD0iRGVzaWduLUNvbXBvbmVudHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJHcm91cC0yIj4KICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbC02LUNvcHktNiIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMS43NSIgY3g9IjgiIGN5PSI4IiByPSI3LjEyNSI+PC9jaXJjbGU+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMi4yNjI1NjMxLDEzLjUgTDEzLjUsMTIuMjYyNTYzMSBMMTguMjcyOTcwOCwxNy4wMzU1MzM5IEwxNy43NDI2NDA3LDE3LjU2NTg2NCBDMTcuMzUyMTE2NCwxNy45NTYzODgzIDE2LjcxODk1MTQsMTcuOTU2Mzg4MyAxNi4zMjg0MjcxLDE3LjU2NTg2NCBMMTIuMjYyNTYzMSwxMy41IEwxMi4yNjI1NjMxLDEzLjUgWiIgaWQ9IkxpbmUiIGZpbGw9ImN1cnJlbnRDb2xvciIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
    // --ui-select: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgYXJpYS1oaWRkZW49InRydWUiIGZvY3VzYWJsZT0iZmFsc2UiIGNsYXNzPSJjc3MtdGo1YmRlLVN2ZyI+PHBhdGggZmlsbGU9ImN1cnJlbnRDb2xvciIgZD0iTTQuNTE2IDcuNTQ4YzAuNDM2LTAuNDQ2IDEuMDQzLTAuNDgxIDEuNTc2IDBsMy45MDggMy43NDcgMy45MDgtMy43NDdjMC41MzMtMC40ODEgMS4xNDEtMC40NDYgMS41NzQgMCAwLjQzNiAwLjQ0NSAwLjQwOCAxLjE5NyAwIDEuNjE1LTAuNDA2IDAuNDE4LTQuNjk1IDQuNTAyLTQuNjk1IDQuNTAyLTAuMjE3IDAuMjIzLTAuNTAyIDAuMzM1LTAuNzg3IDAuMzM1cy0wLjU3LTAuMTEyLTAuNzg5LTAuMzM1YzAgMC00LjI4Ny00LjA4NC00LjY5NS00LjUwMnMtMC40MzYtMS4xNyAwLTEuNjE1eiI+PC9wYXRoPjwvc3ZnPg==");
}
