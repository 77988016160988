.bottomMeta {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: getRem(32);
    grid-gap: getRem(16);

    .thumbsUpItem,
    .bookmarkItem {
        background: none !important;
        border: none;
        // background: rgba(0, 0, 0, 0.5);
        // border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: getRem(2);

        button {
            border-radius: getRem(2);
        }

        span {
            width: auto;
            height: auto;
            transform: none;
            position: static;
        }

        svg {
            width: getRem(18);
            height: auto;
            margin-right: getRem(8);

            path {
                stroke: var(--white);
            }
        }

        p {
            color: var(--white);
            @include font(16, 18);
            font-weight: 700;
            margin: 0;
        }
    }

    .thumbsUpItem {
        padding: getRem(8);

        button {
            display: flex;
            align-items: center;
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            transition: all .2s ease-in-out;

            &:hover {
                background: var(--yellow);
                filter: none !important;

                p {
                    color: var(--black) !important;
                }

                svg {
                    path {
                        &:first-child {
                            fill: var(--black) !important;
                        }

                        &:nth-child(2) {
                            stroke: var(--black) !important;
                        }

                        &:last-child {
                            stroke: var(--black) !important;
                        }
                    }
                }

                &.is-bookmarked {
                    svg {
                        path {
                            fill: var(--black) !important;
                        }
                    }
                }
            }

            p {
                transition: all .2s ease-in-out;
            }

            svg {
                path {
                    transition: all .2s ease-in-out;
                }
            }
        }

        svg {
            path {
                &:first-child {
                    fill: var(--white);
                }

                &:last-child {
                    fill: transparent;
                }
            }
        }
    }

    .bookmarkItem {
        display: flex;
        align-items: center;
        background: none !important;
        border: none !important;
        border-radius: getRem(2);

        button {
            height: getRem(38);
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            margin: 0;
            padding: getRem(8);
            transition: all .2s ease-in-out;

            &:hover {
                background: var(--yellow);
                filter: none !important;

                p {
                    color: var(--black) !important;
                }

                svg {
                    path {
                        stroke: var(--black) !important;
                    }
                }

                &.is-bookmarked {
                    svg {
                        path {
                            fill: var(--black) !important;
                        }
                    }
                }
            }

            svg {
                position: relative;
                z-index: 1;

                path {
                    fill: transparent;
                    transition: all .2s ease-in-out;
                }
            }

            p {
                transition: all .2s ease-in-out;
            }

            &.is-bookmarked {
                svg {
                    path {
                        fill: var(--white);
                    }
                }
            }
        }
    }

    .follow-container {
        @include breakpoint(mobile) {
            width: auto;
        }

        button {
            svg {
                margin-right: getRem(8);
            }

            span {
                margin: 0;
            }
        }
    }

    .share-button-container {
        @include breakpoint(mobile) {
            width: auto;
        }

        button {
            margin: 0;

            &:before,
            &:after {
                display: none;
            }

            svg {
                margin-right: getRem(8);

                path {
                    fill: var(--white);
                    stroke: var(--white);
                }
            }

            span {
                @include font(18, 18);
            }
        }

        ul.share-list {
            background: var(--white);
            position: absolute;
            top: 100%;
            left: 0;

            span {
                color: var(--black);
                text-transform: none;
            }
        }

        .closeBtn {
            width: getRem(36);
            height: getRem(36);
            background: var(--yellow) !important;
            border: none !important;
            border-radius: getRem(4) getRem(4) 0 0 !important;
            display: none;
            padding: getRem(8);
            position: absolute;
            bottom: 100%;
            right: 0;

            @include breakpoint(mobile) {
                display: inline-flex;
            }

            span {
                width: 100%;
                height: 100%;
                font-size: getRem(2);
                opacity: 0;
                position: absolute !important;
                top: 0;
                left: 0;
                z-index: -1;
            }

            svg {
                margin: auto !important;
                position: relative;
                z-index: 1;

                path {
                    fill: var(--black) !important;
                    stroke: transparent !important;
                }
            }
        }
    }

    .follow-container,
    .share-button-container {
        display: inline-block;
        position: relative;

        @include breakpoint(mobile) {
            // width: 100%;
        }

        button.actionButton {
            width: auto;
            min-height: getRem(38);
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: getRem(2);
            color: #FFFFFF;
            display: flex;
            align-items: center;
            padding: getRem(8);
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: var(--yellow) !important;
                color: var(--black) !important;

                svg {

                    g,
                    path {
                        fill: var(--black) !important;
                        stroke: var(--black) !important;
                    }
                }
            }

            span {
                position: static;
                transform: none;
                margin-right: getRem(8);
            }

            svg {
                width: getRem(20);
                overflow: visible;
                z-index: 0;

                g,
                path {
                    transition: all 0.2s ease-in-out !important;
                }

                g {
                    fill: #FFFFFF;
                    transition: all 0.2s ease-in-out;
                }
            }

            &.followButton {
                svg {

                    g,
                    path {
                        transition: all 0.05s ease-in-out !important;
                    }
                }
            }
        }

        .follow-options-container {
            width: getRem(340);
            background: #FFFFFF;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;

            @include breakpoint(tablet) {
                left: auto;
                right: 0;
            }

            @include breakpoint(mobile) {
                width: 80vw;
                left: 0;
                right: auto;
            }

            .max-count-container {
                .top {
                    background: var(--red);
                    box-shadow: 0 getRem(2) getRem(4) rgba(0, 0, 0, 0.3);
                    padding: getRem(12) getRem(16);

                    p {
                        color: #FFFFFF;
                        font-weight: 700;
                        font-size: getRem(18);
                        line-height: getRem(18);
                        margin: 0;
                        text-align: center;
                        text-transform: none;
                    }
                }

                .bottom {
                    padding: getRem(10) getRem(16);

                    p {
                        font-size: getRem(16);
                        line-height: getRem(26);
                        color: #292929;
                        margin: 0;
                        text-transform: none;
                    }

                    a {
                        font-weight: 700;
                        font-size: getRem(16);
                        line-height: getRem(26);
                        color: var(--red);
                        text-transform: none;
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 1;

            li {
                border-bottom: 1px solid rgba(45, 45, 45, 0.2);
                margin: 0;
                padding: getRem(16) getRem(24);
                position: relative;

                &:last-child {
                    border-bottom: none;
                }

                &.disabled {
                    opacity: 0.3;

                    * {
                        pointer-events: none;
                    }
                }

                label {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .content-container {
                    display: flex;
                    align-items: center;
                    display: grid;
                    grid-template-columns: 5fr 3fr 1fr;
                }

                p {
                    color: #292929;
                    font-size: 21px;
                    line-height: 26px;
                    margin: 0 getRem(16) 0 0;
                    text-transform: none;

                    &.type {
                        font-size: 14px;
                        line-height: 24px;
                        color: #777676;
                        // margin: 0 getRem(60) 0 getRem(16);
                    }
                }

                input {
                    margin-left: auto;
                    margin-right: 0;
                    flex-shrink: 0;
                }
            }
        }

        &.open {
            button.actionButton {
                background-color: #DB0007;
            }

            .follow-options-container {
                display: inline-block;
            }
        }
    }
}

.follow-container,
.share-button-container {
    position: relative;

    @include breakpoint(mobile) {
        width: 100%;
    }

    button.actionButton {
        min-height: getRem(38);
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: getRem(2);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        padding: getRem(8);
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;

        span {
            position: static;
            transform: none;
            margin-right: getRem(8);
        }

        svg {
            width: getRem(20);
            overflow: visible;
            z-index: 0;

            g {
                fill: #FFFFFF;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid rgba(45, 45, 45, 0.2);
            margin: 0;
            padding: getRem(16) getRem(24);
            position: relative;

            &:last-child {
                border-bottom: none;
            }

            &.disabled {
                opacity: 0.3;

                * {
                    pointer-events: none;
                }
            }

            label {
                width: 100%;
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }

            .content-container {
                display: flex;
                align-items: center;
                display: grid;
                grid-template-columns: 5fr 3fr 1fr;
            }

            p {
                color: #292929;
                font-size: 21px;
                line-height: 26px;
                margin: 0 getRem(16) 0 0;
                text-transform: none;

                &.type {
                    font-size: 14px;
                    line-height: 24px;
                    color: #777676;
                    // margin: 0 getRem(60) 0 getRem(16);
                }
            }

            input {
                margin-left: auto;
                margin-right: 0;
                flex-shrink: 0;
            }
        }
    }

    .closeBtn {
        display: none !important;

        @include breakpoint(mobile) {
            display: inline-flex !important;
        }
    }

    &.open {
        button.actionButton {
            background-color: #DB0007;
        }

        .follow-options-container {
            display: inline-block;
        }
    }
}

.follow-options-container {
    width: getRem(340);
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;

    @include breakpoint(tablet) {
        left: auto;
        right: 0;
    }

    @include breakpoint(mobile) {
        width: 100%;
        max-width: 80vw;
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        right: auto;
        transform: translate(-50%, -50%);

        .modal-background {
            width: calc(100vw + 2px);
            height: calc(100vh + 2px);
            background-color: rgba(0, 0, 0, 0.8);
            display: inline-block !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
        }
    }

    .modal-background {
        display: none;
    }

    .closeBtn {
        width: getRem(36);
        height: getRem(36);
        background: var(--yellow);
        border: none !important;
        border-radius: getRem(4) getRem(4) 0 0;
        display: none;
        padding: getRem(8);
        position: absolute;
        bottom: 100%;
        right: 0;

        @include breakpoint(mobile) {
            display: inline-flex;
        }

        span {
            width: 100%;
            height: 100%;
            font-size: getRem(2);
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        svg {
            margin: auto !important;
            position: relative;
            z-index: 1;

            path {
                fill: var(--black) !important;
                stroke: transparent !important;
            }
        }
    }

    .max-count-container {
        .top {
            background: var(--red);
            box-shadow: 0 getRem(2) getRem(4) rgba(0, 0, 0, 0.3);
            padding: getRem(12) getRem(16);

            p {
                color: #FFFFFF;
                font-weight: 700;
                font-size: getRem(18);
                line-height: getRem(18);
                margin: 0;
                text-align: center;
                text-transform: none;
            }
        }

        .bottom {
            padding: getRem(10) getRem(16);

            p {
                font-size: getRem(16);
                line-height: getRem(26);
                color: #292929;
                margin: 0;
                text-transform: none;
            }

            a {
                font-weight: 700;
                font-size: getRem(16);
                line-height: getRem(26);
                color: var(--red);
                text-transform: none;
            }
        }
    }

    ul {
        background: var(--white);
        position: relative;
        z-index: 1;
    }
}

.action-container {
    .follow-options-container {
        max-width: getRem(312);
        left: 50%;
        transform: translateX(-50%);
    }
}