textarea {
    resize: vertical;
}

input.hidden {
    display: none;
}

.react-select-container {
    display: inline-block;
    z-index: 1000;

    .react-select {
        &__control {
            height: getRem(48);
            border: 1px solid var(--black);
            border-radius: getRem(2);
        }

        &__value-container {
            @include font(20, 28);
            padding-left: getRem(12);
        }

        &__indicator {
            height: getRem(48);
            width: getRem(48);
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &-separator {
                display: none;
            }

            svg {
                width: getRem(24);
                height: getRem(24);

                path {
                    fill: var(--red);
                }
            }
        }

        &__option {
            @include font(18, 28);

            &__is-selected {
                background: var(--yellow);
                font-weight: bold;
            }
        }

        .option--is-selected {
            background: var(--yellow);
            font-weight: bold;
        }
    }
}