.submitAnIdea {
  --header-height: #{getRem(500)};

  .titleBlock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h1,
    h2,
    h3 {
      margin-top: 0px;
    }

    padding-top: getRem(45);
    @include breakpoint(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  header.ideaHeader {
    --image-clip-size: #{getRem(10000)};
    min-height: var(--header-height);
    padding-top: getRem(24);
    padding-bottom: getRem(150);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--black);
    @include breakpoint(tablet) {
      min-height: #{getRem(400)};
    }
    @include breakpoint(mobile) {
      min-height: #{getRem(350)};
      padding: getRem(16) 0 getRem(40);
    }

    .half {
      padding: 0 getRem(36);
      @include breakpoint(desktop) {
        // transform: translateY(#{getRem(-50)});
        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
          // max-width: 50%;
        }

        & > h1,
        & > div {
          max-width: 55rem;
        }
      }
      @include breakpoint(mobile) {
        padding: 0;
      }

      a {
        color: var(--red);
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: getRem(16);
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: -5;
      background-color: var(--yellow);
      clip-path: circle(
                      var(--image-clip-size) at 100% calc(-1 * var(--image-clip-size) + 100%)
      );
    }
  }

  .submittedContent {
    display: flex;
    align-items: center;
    padding: 50px 0 100px 0;

    @include breakpoint(mobile) {
      flex-direction: column;
      padding: 50px 0;
    }

    .btn {
      margin: 0 50px 0 0;

      @include breakpoint(mobile) {
        margin: 0 0 25px 0;
      }
    }

    .link {
      font-weight: bold;
      text-decoration: none;
      color: var(--red);
      background-color: transparent;
      border: 0;
      appearance: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .formContent {
    position: relative;
    // padding: 0 getRem(36) getRem(80);
    padding-bottom: getRem(80);

    // @include breakpoint(mobile) {
    //   padding: 0;
    // }
  }

  #submitting-overlay {
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.3);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.show {
      display: block;
    }
  }

  form.ideaForm {
    position: relative;
    min-height: 200px;
    background-color: var(--light-grey);
    width: 100%;
    max-width: getRem(880);
    border-radius: getRem(6);
    @include breakpoint(desktop) {
      transform: translateY(getRem(-150));
      padding: getRem(30) getRem(40) getRem(50) getRem(40);
    }
    @include breakpoint(mobile-tablet) {
      padding: getRem(15) getRem(20) getRem(25) getRem(20);
      transform: translateY(getRem(-50));
    }

    .formQuestion:not(:first-of-type) {
      margin-top: 3rem;
      display: flex;
      align-items: center;

      button {
        margin-bottom: 0;
      }

      .lds-roller {
        margin-left: getRem(4);
        transform: scale(.7);
      }
    }

    .required-msg {
      @include font(14, 41);
      text-align: right;
      color: var(--red);
      margin: 0 0 getRem(20) 0;
    }

    .required {
      color: var(--red);
      margin-left: 3px;
    }

    legend {
      margin-bottom: getRem(15);
    }

    legend,
    label:not(.small) {
      text-transform: unset;
      letter-spacing: unset;
      @include font(21, 26);
    }

    label.small {
      text-transform: uppercase;
      letter-spacing: getRem(1.75);
    }

    textarea,
    input[type="text"],
    input[type="email"] {
      width: 100%;
      @include font(21, 26);
      padding: getRem(14) getRem(20);
      // height: getRem(233);
      border-radius: getRem(6);
      border: 1px solid var(--form-border);
    }

    fieldset {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
      appearance: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border: none;

      div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
      }
    }

    button[type="submit"] {
      appearance: none;
      border: 0px solid transparent;
      cursor: pointer;
    }

    &.submitted {
      .is-required {
        position: relative;

        &::before {
          position: absolute;
          z-index: -1;
          content: "";
          pointer-events: none;
          width: calc(100% + 2rem);
          height: calc(100% + 2rem);
          top: -1rem;
          left: -1rem;
          border-radius: getRem(6);
          background-color: transparent;
          transition: all 0.33s md-ease(ease-in-out);
          border: 2px solid transparent;
        }
      }

      .is-required.not-answered {
        &::before {
          border-color: var(--red);
        }

        &::after {
          content: "This field is required.";
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0.25rem 0.5rem;
          background-color: var(--red);
          color: white;
          border-radius: getRem(6);
          font-size: 0.875rem;
        }
      }
    }
  }

  label.required::after {
    content: "*";
    margin-left: 0.25rem;
    white-space: nowrap;
    color: var(--red);
  }
}

button.clearSearch {
  appearance: none;
  background-color: transparent;
  border: 0px solid transparent;
  color: var(--red);
  cursor: pointer;
  font-weight: bold;
  @include font(14, 24);

  &:hover {
    text-decoration: underline;
  }
}
