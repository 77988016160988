@mixin bounce() {
    animation-name: bounce;
    animation-timing-function: md-ease(ease-in-out);
    animation-duration: 1s;
}

@mixin toolbarBounce() {
    animation-name: toolbarBounce;
    animation-timing-function: md-ease(ease-in-out);
    animation-duration: 2s; //1s animation, 1s rest
}

@mixin headShake() {
    animation-name: headShake;
    animation-timing-function: linear;
    animation-duration: 0.5s;
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.05, 0.95) translateY(0);
    }
    30% {
        transform: scale(0.95, 1.05) translateY(-0.5rem);
    }
    50% {
        transform: scale(1.075, 0.975) translateY(0);
    }
    57% {
        transform: scale(1, 1) translateY(-1px);
    }
    64% {
        transform: scale(1, 1) translateY(0);
    }
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes toolbarBounce {
    // Percentages have been set to half to allow 50% 'no animation' between loops
    0% {
        transform: scale(1, 1) translateY(0);
    }
    5% {
        transform: scale(1.02, 0.95) translateY(0);
    }
    15% {
        transform: scale(0.98, 1.05) translateY(-0.5rem);
    }
    25% {
        transform: scale(1.01, 0.975) translateY(0);
    }
    28% {
        transform: scale(1, 1) translateY(-1px);
    }
    32% {
        transform: scale(1, 1) translateY(0);
    }
    50% {
        transform: scale(1, 1) translateY(0);
    }
    // Half of this will be not bouncing
    100% {
        transform: scale(1, 1) translateY(0);
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@keyframes notifShake {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(0);
    }
}

@keyframes headShake {
    0% {
        transform: translate3d(0, 0, 0);
    }

    12.5% {
        transform: translate3d(4px, 0, 0);
    }

    37.5% {
        transform: translate3d(-4px, 0, 0);
    }

    62.5% {
        transform: translate3d(4px, 0, 0);
    }

    87.5% {
        transform: translate3d(-2px, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}
