footer {
    background-color: var(--footer-background);
    display: block;
    min-height: 200px;
    padding: #{getRem(74)} 0 #{getRem(50)};
    z-index: 999;
    position: relative;

    @include breakpoint(mobile) {
        padding-top: getRem(32);
    }
    .footer-content {
        display: grid;
        grid-template-columns: 2fr 1fr;
        @include breakpoint(mobile-tablet) {
            grid-template-columns: 1fr;
        }
    }
    .links {
        min-height: getRem(190);
        ul {
            column-count: 3;
            list-style: none;
            margin: 0 0 3rem 0;
            padding: 0;
            @include breakpoint(mobile) {
                column-count: 1;
                margin-bottom: 2em;
            }
        }
        li {
            margin-right: var(--gap);
            font-size: getRem(24);
            line-height: getRem(29);
            margin-bottom: calc(var(--gap) / 2 - 0.125em);
            @include breakpoint(tablet) {
                margin-bottom: 1em;
            }
        }
        a {
            color: var(--text);
            display: inline-block;
            &:hover,
            &:focus {
                color: var(--red);
                text-decoration: none;
            }
        }
    }
    .cta {
        @include breakpoint(desktop) {
            text-align: left;
        }
        @include breakpoint(tablet) {
            margin-bottom: getRem(47);
        }
        @include breakpoint(mobile) {
            margin-bottom: getRem(51);
        }
    }
    .copyright {
        margin-top: getRem(24);
        
        ul {
            list-style: none;
            margin: 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            @include breakpoint(mobile) {
                display: grid;
                grid-template-columns: auto 1fr;
                justify-content: flex-start;
                gap: getRem(30);
            }
        }
        li {
            font-size: getRem(14);
            @include lineHeightRatio(14, 14);
            @include breakpoint(tablet-desktop) {
                &:not(:last-child) {
                    margin-right: getRem(30);
                }
            }
            @include breakpoint(mobile) {
                &:first-child {
                    grid-column: 1 / span 2;
                }
            }
        }
        li a {
            font-size: getRem(16);
            @include lineHeightRatio(16, 16);
            font-weight: bold;
            color: var(--text);
            &:hover,
            &:focus {
                color: var(--red);
                text-decoration: none;
            }
        }
    }

    &.fgd-footer {
        min-height: 0;

        .links {
            min-height: 0;

            ul {
                margin-bottom: 0;

                li {
                    margin-bottom: 0;
                }
            }
        }

        .cta {
            p {
                margin: 0;
            }
        }
    }

    &.fgd-footer {
        flex: 0 1 auto;
        padding: getRem(60) 0;
        
        @include breakpoint(mobile-tablet) {
            padding: getRem(32) 0;
        }

        .footer-content {
            @include breakpoint(mobile-tablet) {
                grid-template-columns: 2fr 1fr;
            }

            @include breakpoint(mobile) {
                grid-template-columns: 1fr;
            }
        }
        
        .cta {
            margin-bottom: 0;

            @include breakpoint(mobile-tablet) {
                display: none;
            }

            p {
                text-align: right;
            }
        }
    }
}
