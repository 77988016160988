.page.searchPage {
	--cheese-height: #{getRem(58)};
	z-index: 1000;
	position: relative;

	h1 {
		// @include font(40, 48);
		@include font(21, 26);

		// font-weight: normal;
		.searchTerm {
			color: var(--red);
		}
	}

	h2 {
		@include font(21, 26);
		font-weight: normal;
		margin-top: 0;

		&>div.ais-Stats {
			display: none;
		}

		.searchTerm {
			color: var(--red);
		}
	}

	&>.wrapper {
		padding: getRem(100) 0 0;

		@include breakpoint(mobile) {
			padding: getRem(40) getRem(24) 0;
		}
	}

	&::before {
		content: "";
		height: var(--cheese-height);
		width: 100%;
		position: absolute;
		background-color: var(--yellow);
		top: var(--nav-height);
		left: 0;
		z-index: 1;
	}

	&::after {
		content: "";
		width: getRem(287);
		height: var(--cheese-height);
		position: absolute;
		top: calc(var(--nav-height) + var(--cheese-height));
		// background-color: rebeccapurple;

		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjY2IiBoZWlnaHQ9IjU4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0tOS4xNDcgMEguNTM5QzMwLjMxIDEuMjEzIDQ1LjM5NyAzMi4xNjIgNzYuODggMjcuM2MxMS4zNi0xLjc1MiAyMC44MTUtOC4zOTYgMzIuNjgtOC40OTQgMjguMzk2LS4yMyA0Mi4zMzYgMzIuNTAyIDY4LjgyNyAzOC4xNzUgNy45NDQgMS43MDIgMTYuMjg0IDEuNDc4IDIzLjYzNC0yLjI1NyA2LjQ0Ny0zLjI3NCAxMS40NTUtOC42OTcgMTUuNzk1LTE0LjM2NyA3LjUtOS44MDQgMTQuMTgtMjAuNDMgMjMuMTQ2LTI5LjAxMyA0Ljg3OC00LjY2NSAxMC43NjQtOC45OTcgMTcuNDQ4LTEwLjQyIDIuNTk2LS41NTMgNS4yMTktLjc4OCA3Ljg0Ny0uOTI0aDEwLjkxIiBmaWxsPSIjRkZCQzBEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
		background-size: 100% 100%;
		background-position: top center;
		right: auto;
		// left: calc(50% - (#{getRem(287)}) + (var(--max-width) / 2) - #{getRem(85)});
		left: calc(100% - 287px);

		@include breakpoint(mobile-tablet) {
			//left: calc(50% - (#{getRem(287)} / 2) + (var(--max-width) / 2));
		}
	}

	#results {
		gap: 2rem;

		@include breakpoint(desktop) {
			display: grid;
			grid-template-columns: #{getRem(250)} auto;
			margin-bottom: getRem(114);
			align-items: flex-start;

			button.close {
				display: none;
			}
		}

		// position: relative;
		@include breakpoint(mobile-tablet) {
			button.close {
				display: block;
				position: absolute;
				top: 0.5em;
				right: 0.5em;
				background: transparent;
				appearance: none;
				border: 1px solid transparent;
			}

			h2.small {
				max-width: getRem(500);
				margin: 0 auto 1em auto;
			}

			aside {
				position: fixed;
				top: var(--nav-height);
				right: 0;
				background-color: white;
				width: calc(100% - 1rem);
				height: calc(100vh - 1rem - var(--nav-height));
				border-bottom-left-radius: getRem(30);
				z-index: 100;
				overflow-y: auto;
				box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.51);
				padding: getRem(50) 2rem;
				// &::after {
				//     content: "";
				//     position: fixed;
				//     top: 0;
				//     left: 0;
				//     // background-color: rebeccapurple;
				//     width: 100%;
				//     height: 100%;
				//     z-index: 10;
				//     background-image: linear-gradient(
				//         to bottom,
				//         white 0%,
				//         transparent getRem(100),
				//         transparent calc(100% - #{getRem(100)}),
				//         white 100%
				//     );
				// }
				transition: transform 0.66s md-ease(ease-in-out),
					box-shadow 0.66s md-ease(ease-in-out);

				&.mobile-open {
					transform: translate3d(0, 0, 0);
				}

				&.mobile-closed {
					transform: translate3d(100%, 0, 0);
					box-shadow: 0 0 0 0 rgba(black, 0);
				}

				&[data-nav-sticky="false"] {
					top: 0;
					height: calc(100vh - 1rem);
				}

				button.close svg {
					transform: rotate(45deg);
				}
			}
		}

		@include breakpoint(mobile) {
			#sidebar-sort {
				ul {
					list-style: none;
					display: block;
					margin: 0;
					padding: 0.5rem 0;

					li {
						width: 100%;
						padding: 1rem;
						border-top: 1px solid var(--form-border);
						border-bottom: 1px solid transparent;

						&:last-child {
							border-bottom-color: var(--form-border);
						}

						button {
							appearance: none;
							padding: 0;
							margin: 0;
							border: none;
							background-color: transparent;
						}
					}
				}

				button.btn {
					border: none;
					width: 100%;
					margin-top: 2rem;
				}
			}
		}
	}

	.titleArea {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include breakpoint(mobile-tablet) {
			margin-bottom: 1rem;

			h2.small {
				text-align: left;
				margin: 0 !important;
			}

			button {
				appearance: none;
				font-weight: bold;
				padding: 0;
				border: none;
				background-color: transparent;

				&:not(:last-of-type) {
					margin-right: 1.5rem;
				}
			}
		}

		.sort {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: auto;

			@include breakpoint(mobile) {
				margin-left: 0;
			}

			.basic-single {
				min-width: getRem(160);
			}

			.select__control {
				background-color: var(--light-grey);
				border-color: var(--form-border);
			}

			label {
				line-height: 1em;
				padding: 0;
				margin: 0 1rem 0 0;
			}

			&.hide-desktop {
				@include breakpoint(desktop) {
					display: none;
				}
			}

			&.show-desktop {
				@include breakpoint(mobile-tablet) {
					display: none;
				}
			}
		}
	}

	.search-cards {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li.ais-Hits-item {
				border-top: 1px solid (rgba(#2d2d2d, 0.2));

				&:last-of-type {
					border-bottom: 1px solid (rgba(#2d2d2d, 0.2));
				}
			}
		}
	}

	.people-cards {
		display: block;

		.ais-InfiniteHits-list {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: getRem(16);

			@include breakpoint(tablet) {
				grid-template-columns: 1fr 1fr;
			}

			@include breakpoint(mobile) {
				grid-template-columns: 1fr;
			}
		}

		.people-card {
			width: 100%;
			height: 100%;
			background: #F7F7F7;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			display: inline-block;
			margin-bottom: getRem(16);
			padding: getRem(24);

			&:hover,
			&:focus {
				outline: 2px solid var(--yellow);
				text-decoration: none;
			}
		}

		.profile-pic {
			width: getRem(100);
			height: getRem(100);
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		h2.name {
			color: #111111;
			font-weight: 700;
			font-size: 24px;
			line-height: 30px;
			margin: getRem(16) 0 0;
		}

		p.position {
			color: #292929;
			font-weight: 400;
			font-size: 16px;
			line-height: 26px;

			&+.list-container {
				margin-top: getRem(32);
			}
		}

		.lists {
			background: #FFFFFF;
			margin-top: getRem(16);
			padding: getRem(16);
		}

		.list-container {
			margin-top: getRem(16);

			&:first-child {
				margin-top: 0;
			}
		}

		h3 {
			color: #292929;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: 1.75px;
			margin: 0;
			text-transform: uppercase;
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				color: #292929;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.wrapper-stravito-search {
		padding-top: getRem(30);
	}

	.stravito-results-num {
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;
		border-bottom: 1px solid rgba(#2d2d2d, 0.2);

		p {
			font-size: getRem(21);
			margin: 0;
		}
	}

	// Algolia Search Box
	.search-container {
		display: flex;

		@include breakpoint(mobile) {
			flex-direction: column;
			margin-top: getRem(40);
			margin-bottom: getRem(16);
		}

		.ais-SearchBox {
			flex: 1;
		}

		.buttons-container {
			display: flex;
			align-items: center;
		}

		.follow-btn-container {
			position: relative;
			margin: auto 0 auto getRem(90);

			@include breakpoint(mobile) {
				margin-left: 0;
			}

			button {
				border-radius: getRem(4);
				margin-bottom: getRem(4);

				&:before,
				&:after {
					border-radius: 0;
				}

				span {
					padding-top: getRem(8);
					padding-bottom: getRem(8);
				}
			}

			.max-count-container {
				width: getRem(340);
				background: var(--white);
				box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
				display: none;
				position: absolute;
				top: 100%;
				right: 0;
				z-index: 100;

				.top {
					background: var(--red);
					box-shadow: 0 getRem(2) getRem(4) rgba(0, 0, 0, 0.3);
					padding: getRem(12) getRem(16);

					p {
						color: #FFFFFF;
						font-weight: 700;
						font-size: getRem(18);
						line-height: getRem(18);
						margin: 0;
						text-align: center;
						text-transform: none;
					}
				}

				.bottom {
					padding: getRem(10) getRem(16);

					p {
						font-size: getRem(16);
						line-height: getRem(26);
						color: #292929;
						margin: 0;
						text-transform: none;
					}

					a {
						font-weight: 700;
						font-size: getRem(16);
						line-height: getRem(26);
						color: var(--red);
						text-transform: none;
					}
				}
			}

			.error-message {
				width: 100%;
				border-radius: getRem(4);
				box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.24);
				@include font(16, 24);
				margin: 0;
				padding: getRem(8);
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
			}

			&:hover {
				.max-count-container {
					display: inline-block;
				}
			}
		}

		.share-button-container {
			margin-left: getRem(8);

			&>button {
				background: var(--red);
				border: none;
				border-radius: getRem(4);
				margin-bottom: getRem(4);

				span {
					color: var(--white);
					font-weight: 700;
					margin-left: getRem(8);
				}

				svg {
					path {
						fill: var(--white);
						stroke: var(--white);
					}
				}
			}


		}
	}

	form.ais-SearchBox-form,
	form.file-search-form {
		position: relative;
		padding: 0;
		border-bottom: 1px solid black;
		margin: 2rem 0;

		input {
			font-size: 2em;
			font-weight: bold;
			padding: getRem(12) 0 getRem(12) getRem(12);
			border-radius: 0px;
			appearance: none;
			background-color: white;
			border: 0px solid transparent;
			margin: 0;
			width: 100%;

			&:focus {
				outline: 1px solid var(--yellow);
				border-top: 0 !important;
				border-right: 0 !important;
				border-left: 0 !important;
				box-shadow: none;
			}
		}

		button {
			width: getRem(60);
			height: calc(100% - 8px);
			appearance: none;
			background-color: transparent;
			border: 0px solid transparent;
			border-radius: none;
			position: absolute;
			top: getRem(4);
			right: getRem(4);
			cursor: pointer;
			border-radius: getRem(6);

			&:hover {
				background-color: rgba(#ffbc0d, 1);
			}

			&[type="submit"] {
				background-image: url("/images/search.svg");
				color: black;
				background-position: center;
				background-repeat: no-repeat;
				// background-size: 19px auto;
				display: none;

				svg {
					visibility: hidden;
				}
			}

			&[type="reset"] {
				// display: none;
				// right: getRem(76);
			}
		}
	}

	// Misc webkit - apply globally?

	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	.ais-InfiniteHits .ais-InfiniteHits-loadMore {
		@extend .btn;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin: 1rem auto !important;
		display: block;
		cursor: pointer;

		&:disabled {
			display: none;
		}
	}

	.ais-CurrentRefinements-list {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		li {
			background-color: var(--yellow);
			font-weight: bold;
			@include font(16, 18);
			border-radius: getRem(6);
			padding: 0.25em 0.5em;

			&:not(:first-child) {
				margin-left: 1rem;
			}

			button {
				appearance: none;
				background-color: transparent;
				border: none;
				margin-left: 1rem;
				width: getRem(20);
				height: getRem(20);
				border-radius: 100%;
				text-align: center;
				padding: 0;
				cursor: pointer;

				&:hover {
					background-color: var(--black);
					color: var(--yellow);
				}
			}
		}
	}

	.related-results {

		&-wrapper {
			margin-block: -2rem 2rem;

			@include breakpoint(mobile) {
				max-width: 100%;
			}
		}

		&-component {
			display: flex;
			align-items: center;
			gap: 1rem;
			max-width: calc(100% - 350px);
			padding: 1rem 1.5rem;
			background-color: var(--light-grey);

			@include breakpoint(mobile) {
				max-width: 100%;
			}
		}

		&-label {
			font-size: 1.25rem;
			font-weight: 700;
			line-height: 1.2;
			margin: 0;
		}

		&-list {
			display: flex;
			gap: getRem(16);
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&-item {
			font-size: 1.125rem;
			font-weight: 400;
			line-height: 1.33;
			color: var(--black);
			text-decoration: none;

			&:last-child {
				&::after {
					display: none;
				}
			}

			&:hover {
				span {
					border-color: #DB0007;
				}
			}

			&::before {
				content: '';
				display: inline-block;
				width: getRem(18);
				height: getRem(18);
				background: url('/../../images/icon-related-search-item.svg');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				margin-right: getRem(4);
				transform: translateY(getRem(4))
			}

			&::after {
				content: '';
				display: inline-block;
				width: 1px;
				height: getRem(16);
				padding-left: getRem(16);
				border-right: 1px solid var(--black);
			}

			span {
				border-bottom: 3px solid transparent;
				transition: border-color .3s ease;
			}
		}
	}

	.file-swap-component {
		$borderColor: rgba(#2d2d2d, 0.2);

		//border-bottom: 1px solid $borderColor;
		.wrapper {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 0;

			h1 {
				font-weight: 400;
				font-size: 20px;
				line-height: 24px;
				color: #292929;
				margin: 0 0 0 getRem(30);

				@include breakpoint(mobile) {
					margin: getRem(16) 0 0;
				}
			}

			.buttons {
				//margin-left: getRem(24);
			}

			.message {
				font-size: 0.8em;

				span.small {
					font-size: 0.8em;
				}
			}

			.buttons {
				//margin-bottom: -9px;
				display: flex;
				align-items: flex-start;
				gap: getRem(12);

				@include breakpoint(mobile-tablet) {
					flex-wrap: wrap;
					padding: 0 1.5rem;
				}
			}

			button {
				background: var(--light-grey);
				border: 1px solid var(--form-border);
				border-radius: getRem(4);
				display: flex;
				align-items: center;
				@include font(20, 28);
				padding: getRem(6) getRem(8);
				transition: all .2s ease-in-out;

				svg {
					margin-right: getRem(8);
				}

				&.active {
					background: var(--yellow);
					border-color: var(--yellow);
					font-weight: bold;
				}
			}
		}
	}

	.ais-RangeInput {
		display: none;
	}

	.nowrap {
		max-width: 100%;
		text-overflow: ellipsis;
		display: inline-block;
		margin-left: getRem(4);
	}

	//All Results/FGD Search Page
	.all-search-page,
	.fgd-search-page {
		&>.wrapper {
			padding: 0;

			&:first-child {
				padding-top: getRem(100);
			}

			@include breakpoint(mobile) {
				padding: 0 getRem(24);

				&:first-child {
					padding-top: getRem(40);
				}
			}
		}

		.results-group {
			padding: getRem(32) 0;

			&:first-child {
				margin-top: getRem(20);
			}

			&:not(:last-child) {
				border-bottom: 1px solid #ADADAD;
			}
		}

		h2 {
			@include font(24, 32);
			font-weight: bold;
		}

		#results {
			display: block;
		}

		.people-cards .ais-InfiniteHits-list {
			grid-template-columns: repeat(4, 1fr);

			@include breakpoint(tablet) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include breakpoint(mobile) {
				display: block;
			}
		}

		.ais-InfiniteHits .ais-InfiniteHits-loadMore {
			margin: getRem(32)0 0 !important;
		}
	}

	&.fgdSearchPage {
		min-height: calc(100vh - 153px);

		&>.wrapper {
			padding-top: getRem(52);

			@include breakpoint(tablet) {
				padding-top: getRem(80);
			}

			.wrapper-inner {
				width: calc(100% - 200px);

				@include breakpoint(mobile-tablet) {
					width: 100%;
				}
			}
		}

		#results {
			display: block;
		}
	}
}