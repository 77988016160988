@import "../global/mixins";
@import "../global/animations";

.dialog-layer,
.dialogLayer {
  width: 100vw;
  height: 100vh;
  //background: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .dialogLayerInner {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }

  .background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .dialogBox {
    max-height: 80vh;
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    margin: auto;
    padding: getRem(24) getRem(32);
    position: relative;
    z-index: 1;

    .headerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: getRem(16);
    }

    .closeBtn {
      background-color: transparent;
      border: none;
      padding: 0;
      position: relative;
      transform: rotate(45deg);

      @include breakpoint(mobile) {
        position: absolute;
        top: getRem(16);
        right: getRem(16);
      }

      span {
        width: 22px;
        height: 22px;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        text-indent: -9000px;
        z-index: -1;
      }
    }

    h2 {
      font-size: 28px;
      letter-spacing: 0;
      line-height: 32px;
      margin: 0;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      div {
        width: 100%;
      }

      input {
        width: 100%;
        max-width: getRem(468);
        height: getRem(48);
        background-color: #F7F7F7;
        border: 1px solid #979797;
        border-radius: 2px;
        font-size: 14px;
        color: #292929;
        //letter-spacing: 1.75px;
        line-height: 18px;
        margin-bottom: 0;
      }

      button {
        cursor: pointer;
        margin: getRem(36) auto 0;
      }

      .folderWarning {
        width: 100%;
        color: var(--red);
        font-weight: bold;
        margin: 0;
        text-align: center;

        &+button {
          margin-top: getRem(16);
        }
      }
    }

    .cancel {
      background: none;
      border: none;
      font-size: 14px;
      font-weight: 600;
      color: var(--red);
      cursor: pointer;
      letter-spacing: 0;
      line-height: 24px;
      margin: getRem(8) auto 0;
    }
  }
}

.folderEditContainer {
  width: getRem(640);
  max-width: 80vw;
  border-radius: getRem(6);
  display: block !important;
  overflow: auto;

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
      width: calc(50% - 4px);
      //max-width: getRem(268);
      background: #F7F7F7;
      border-radius: getRem(6);
      margin-right: getRem(4);
      margin-bottom: getRem(10);
      position: relative;
      border: 1px solid black;

      @include breakpoint(mobile) {
        width: 100%;
        margin-top: getRem(8);
      }

      input {
        border: 1px solid #292929;
        letter-spacing: normal;
      }

      label {
        width: 100%;
        height: 100%;
        background: transparent;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .inputContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: getRem(24) getRem(14);

        div {
          padding-right: getRem(8);
        }
      }

      p {
        letter-spacing: normal;
        text-transform: none;
      }

      .title {
        font-weight: 700;
        font-size: getRem(32);
        line-height: getRem(40);
        margin: 0;
      }

      .count {
        font-weight: 400;
        font-size: getRem(16);
        line-height: getRem(26);
        text-transform: none;
        margin: 0;
      }

      &.checked {
        background: #2D2D2D;

        p {
          color: white;
        }

        input {
          background-color: transparent;
        }
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
  }
}

.confirmationContainer {
  align-items: center;
}

.legalPopupContainer {
  width: 63.5vw;
  max-width: getRem(915);
  //max-height: getRem(600);
  background-color: #F7F7F7;
  border-radius: getRem(6);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
  flex-wrap: nowrap !important;
  padding: getRem(48) getRem(24) !important;

  @include breakpoint(mobile) {
    width: 80vw;
    padding: getRem(16) !important;
  }

  .headerContainer {
    border-bottom: 1px solid rgba(#969696, .2);
    display: block !important;
    padding: 0 getRem(16) getRem(24);

    @include breakpoint(mobile) {
      padding: 0;
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #111111;
      margin: 0;
    }

    p {
      font-style: italic;
      font-weight: 400;
      font-size: getRem(22);
      line-height: getRem(32);
      color: #111111;
      margin: getRem(8) 0 0;

      @include breakpoint(mobile) {
        font-size: getRem(18);
        line-height: getRem(24);
      }

      &.subtitle {
        font-weight: bold;
      }
    }
  }

  .bodyContainer {
    max-height: getRem(283);
    margin-top: getRem(8);
    overflow: auto;
    padding: getRem(24);

    @include breakpoint(mobile) {
      padding: getRem(16);
    }

    &::-webkit-scrollbar-thumb {
      background: black;
    }

    &::-webkit-scrollbar-track {
      background: blue;
    }

    &>ol {
      counter-reset: item;
      list-style: none;

      &>li {
        counter-increment: item;

        &:before {
          content: counter(item) ".";
          display: inline-block;
          font-size: getRem(18);
          line-height: getRem(28);
          font-weight: bold;
        }
      }
    }

    ol,
    ul {
      padding-left: 0;
    }

    ul {
      list-style: initial;
      list-style-position: inside;
    }

    h3 {
      font-size: getRem(24);
      line-height: getRem(32);
      color: #292929;
      margin: 0;
    }

    h4 {
      display: inline-block;
      font-size: getRem(18);
      line-height: getRem(28);
      margin: 0;

      &:first-child {
        margin-left: getRem(4);
      }

      &+p {
        margin-top: getRem(4);
      }
    }

    li,
    p {
      font-size: getRem(16);
      line-height: getRem(26);
      margin: getRem(16) 0 0;
    }

    .disclaimer {
      text-transform: uppercase;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    margin-top: getRem(48);
    padding: 0 getRem(16);

    @include breakpoint(mobile) {
      display: block;
      padding: 0;
    }

    .btn {
      margin: 0;

      &:disabled {
        opacity: 0.5;
      }
    }

    p {
      color: #131415;
      font-weight: 700;
      font-size: getRem(14);
      line-height: getRem(24);
      margin: 0 0 0 getRem(16);

      @include breakpoint(mobile) {
        margin: getRem(8) 0 0;
      }
    }
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: getRem(16);
  padding: 0 getRem(16);

  @include breakpoint(mobile) {
    display: block;
    padding: 0;
  }

  .errorMsg {
    color: var(--red);
    @include font(16, 28);
    font-weight: bold;
    margin: 0;
  }
}

.mediaContainer {
  width: 80vw;
  max-width: getRem(1080);
  max-height: 85vh !important;

  &.audioContainer {
    width: auto;
  }

  .mediaContent {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
  }

  .videoContainer {
    width: 100%;
    max-width: getRem(1050);
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;

    iframe,
    video {
      width: 100%;
      max-width: getRem(1050);
      height: 100%;
      max-height: getRem(590);
    }
  }

  .audioContent {
    width: 100%;
    max-width: getRem(1050);
    // aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .galleryContainer {
    width: 100%;

    &>div {
      width: 100%;
    }
  }
}

.galleryModal {

  .mediaContainer,
  .mediaContent {
    height: 100%;
  }

  :global {

    .swiper,
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;
    }

    .box {
      width: calc(100% - 80px);
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: auto;

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        position: relative;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          position: absolute;
          object-fit: contain;
        }
      }

      .caption {
        flex: 0 1 auto;
      }
    }
  }
}

.fgdGalleryModal,
.lightboxModal {
  .mediaContainer {
    max-width: getRem(820);
    height: 80vh;
    max-height: getRem(620) !important;
    border-radius: getRem(16);
    display: flex;
    .closeBtn {
      width: getRem(40);
      height: getRem(40);
      cursor: pointer;
      position: absolute;
      top: getRem(30);
      right: getRem(30);
    }
  }
  .mediaContent {
    width: 90%;
    height: 100%;
    margin: auto;
  }
  :global {
    .swiper,
    .swiper-wrapper {
      height: 100%;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      figure {
        width: 100%;
        height: 100%;
        max-width: calc(100% - 150px);
        display: flex;
        img {
          width: auto;
          height: 100%;
          max-width: 100%;
          max-height: getRem(500);
          margin: auto;
        }
      }
      figcaption {
        color: var(--text-color);
        @include font(16, 26);
        text-align: center;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: getRem(50);
      height: getRem(50);
      background: var(--yellow);
      border-radius: 50%;
      &:after {
        background: url('/../../images/ui-gallery-arrow.svg') 50% no-repeat;
        background-size: contain;
      }
    }
    .swiper-button-prev {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

.lightboxModal {
  .mediaContent {
    height: calc(100% - 40px);
    margin: auto auto 0;
  }

  .imageContainer {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}