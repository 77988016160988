.terms {
    width: calc(100% - 25px);
    max-width: 700px;
    margin: 150px auto 100px auto;

    @include breakpoint(tablet) {
        margin: 100px auto 50px auto;
    }

    @include breakpoint(mobile) {
        margin: 100px auto 50px auto;
    }

    h1 {
        font-size: getRem(42);
    }

    h2 {
        font-size: getRem(28);
    }

    h3 {
        font-size: getRem(20);
    }

    p,
    li {
        font-size: getRem(16);
    }

    ul {
        margin: 0 0 0 20px;
        padding: 0;
    }
}
