div.pageComponent.happy-meal-page {
  display: flex;
  justify-content: space-between;
  margin-top: var(--nav-height);
  padding-bottom: getRem(128);

  .wrapper {
    max-width: getRem(1440);
    display: flex;
    justify-content: space-between;
    padding-top: getRem(80);
    padding-left: getRem(110);
    padding-right: getRem(110);
    position: relative;
    z-index: 1;
    @include breakpoint(mobile-tablet) {
      padding-top: getRem(40);
      padding-left: getRem(20);
      padding-right: getRem(20);
    }
  }

  .content-column {
    width: calc(100% - 314px);
    position: relative;
    z-index: 1;
    @include breakpoint(mobile-tablet) {
      width: 100%;
    }
  }

  .anchor-links {
    width: getRem(273);
    max-height: calc(100vh);
    background: #FFFFFF;
    border-top: 4px solid var(--red);
    border-radius: getRem(8);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: auto;
    padding: getRem(16) getRem(24) getRem(24);
    position: sticky;
    top: getRem(80);
    right: 0;

    &.responsive {
      display: none;
      @include breakpoint(mobile-tablet) {
        display: block;
      }
    }

    &.desktop {
      @include breakpoint(mobile-tablet) {
        display: none;
      }
    }
    @include breakpoint(mobile-tablet) {
      width: 100%;
      margin-top: getRem(24);
    }

    p {
      color: var(--red);
      @include font(14, 18);
      font-weight: 700;
      margin: 0;
    }

    ul {
      list-style: none;
      margin-top: getRem(16);
      padding: 0;

      li {
        &:not(:first-child) {
          margin-top: getRem(16);
        }

        a {
          color: #000000;
          @include font(20, 24);
        }
      }
    }
  }

  .background {
    width: 100%;
    height: getRem(547);
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    img {
      width: 100%;
    }
  }

  .banner {
    position: relative;

    .text-container {
      position: relative;
      z-index: 1;
    }

    .title {
      display: flex;
      align-items: center;
      margin-top: getRem(36);
      @include breakpoint(mobile-tablet) {
        margin-top: 0;
      }
      @include breakpoint(mobile) {
        flex-direction: column;
        align-items: center;
      }

      .logo-container {
        min-width: getRem(120);
        height: getRem(120);
        background: #FFFFFF;
        border: 2px solid var(--yellow);
        border-radius: 4px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: getRem(12);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        margin-left: getRem(24);
        @include breakpoint(mobile) {
          margin-top: getRem(24);
          margin-left: 0;
        }

        p {
          color: rgb(192, 0, 0);
          @include font(14, 18);
          text-transform: uppercase;
          margin: 0;
        }

        h1 {
          color: rgb(192, 0, 0);
          @include font(64, 80);
          margin: 0;
          @include breakpoint(mobile) {
            @include font(42, 48);
          }

          span {
            display: block;
          }
        }
      }
    }

    .yellow-box {
      width: 100%;
      // background: var(--yellow);
      background: #FFFFFF;
      border-top: getRem(6) solid var(--yellow);
      border-radius: getRem(8);
      margin-top: getRem(64);
      padding: getRem(34) getRem(44);

      @include breakpoint(mobile) {
        margin-top: getRem(32);
        padding: getRem(24) getRem(16);
      }

      *::selection {
        background: var(--red);
      }

      p {
        @include font(18, 28);
        margin-bottom: 0;

        &:first-child {
          margin-top: 0;
        }
        @include breakpoint(mobile) {
          @include font(16, 26);
        }
      }
    }

    .disclaimer {
      width: 100%;
      background: #F7F7F7;
      margin-top: getRem(16);
      padding: getRem(36) getRem(60);
      @include breakpoint(mobile) {
        padding: getRem(24);
      }

      p {
        @include font(16, 24);
        font-style: italic;
      }
    }
  }

  .campaigns-section {
    margin-top: getRem(18);

    .campaign-section {
      //width: calc(100% - 314px);
      border-bottom: 1px solid #ADADAD;
      padding: getRem(46) 0;

      @include breakpoint(mobile) {
        padding: getRem(32) 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    h2 {
      display: inline-block;
      @include font(48, 56);
      margin: 0;
      padding-bottom: getRem(24);
      position: relative;
      @include breakpoint(mobile) {
        @include font(24, 30);
      }

      &:after {
        content: '';
        width: 100%;
        height: getRem(16);
        background: var(--yellow);
        border-top-right-radius: getRem(16);
        border-bottom-left-radius: getRem(16);
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        @include breakpoint(mobile) {
          height: getRem(8);
          border-top-right-radius: getRem(8);
          border-bottom-left-radius: getRem(8);
        }
      }
    }

    .description {
      @include font(17, 36);
      margin-top: getRem(48);

      @include breakpoint(mobile) {
        @include font(16, 26);
        margin-top: getRem(24);
      }
    }

    .grid-container {
      display: flex;
      flex-direction: column;
      grid-column-gap: getRem(20);
      grid-row-gap: getRem(40);
      list-style: none;
      padding: 0;

      @include breakpoint(mobile) {
        display: flex !important;
        grid-row-gap: getRem(24);
      }

      &.layout-1,
      &.full {
        .grid-item {
          .image-container {
            height: getRem(360);

            @include breakpoint(mobile) {
              height: getRem(200);
            }
          }
        }
      }

      &.layout-2,
      &.half,
      &.layout-3,
      &.thirds,
      &.layout-4,
      &.fourths {
        display: grid;
        grid-column-gap: getRem(20);
        grid-row-gap: getRem(40);
      }

      &.layout-2,
      &.half {
        grid-template-columns: 1fr 1fr;
        //h3 {
        //  grid-column: 1/3;
        //}
        .grid-item {
          .image-container {
            height: getRem(250);
          }
        }
      }

      &.layout-3,
      &.thirds {
        grid-template-columns: 1fr 1fr 1fr;
        //h3 {
        //  grid-column: 1/4;
        //}
        .grid-item {
          .image-container {
            height: getRem(166);
            min-height: getRem(166);
          }

          &.image-item {
            .image-container {
              height: getRem(295);
            }
          }
        }
      }

      &.layout-4,
      &.fourths {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        //h3 {
        //  grid-column: 1/5;
        //  margin-top: getRem(40);
        //}
      }

      h3 {
        width: 100%;
        @include font(32, 40);
        margin-top: getRem(40);
        margin-bottom: getRem(24);
        @include breakpoint(mobile) {
          @include font(24, 30);
        }
      }

      li {
        min-width: 25%;
      }

      .grid-item {
        width: 100%;
        display: inline-block;
        flex-grow: 1;
        position: relative;

        .media-popup-link {
          width: 100%;
          height: 100%;
          color: transparent !important;
          display: inline-block;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:focus,
        &:hover {
          text-decoration: none;

          .image-container {
            div {
              transform: scale(1.1);
            }
          }

          .link-text {
            text-decoration: underline;
          }
        }

        div {
          transform: scale(1);
          transition: all 0.2s ease-in-out;
        }

        .image-container {
          width: 100%;
          min-height: getRem(229);
          border: 1px solid rgba(173, 173, 173, 0.5);
          border-radius: getRem(8);
          overflow: hidden;
          position: relative;

          @include breakpoint(mobile) {
            height: auto !important;
            min-height: getRem(166);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain !important;
          }
        }

        .title {
          @include font(20, 24);
          margin-top: getRem(8);
          margin-bottom: 0;
        }

        .link-text {
          color: var(--red);
          display: inline-block;
          @include font(16, 24);
          font-weight: 700;
          margin-top: getRem(8);
          margin-bottom: 0;
        }
      }
    }
  }

  .popup {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    &__inner {
      width: 90vw;
      max-width: getRem(1000);
      max-height: 90vh;
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
      padding: getRem(48);
      position: relative;

      @include breakpoint(mobile) {
        padding: getRem(24) getRem(16);
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;

      h2 {
        @include font(32, 40);
        margin: 0;

        @include breakpoint(mobile) {
          @include font(24, 32);
        }
      }

      button {
        width: getRem(22);
        height: getRem(22);
        background: none;
        border: none;
        cursor: pointer;
        flex-shrink: 0;
        margin-top: getRem(9);
        margin-left: getRem(16);
        padding: 0;
        position: relative;

        span {
          width: 100%;
          height: 100%;
          font-size: getRem(2);
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }

        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: rotate(45deg);
        }
      }
    }

    .iframe-container {
      margin-top: getRem(24);
      position: relative;
      padding-top: 50%;

      @include breakpoint(mobile) {
        margin-top: getRem(32);
      }

      &.pdf-container {
        padding-top: 60%;
      }
    }

    .image-container {
      height: 70vh;
      margin-top: getRem(24);
      position: relative;

      div, span {
        height: 100%;
        position: static !important;

        img {
          width: 100% !important;
          height: 100% !important;
          // position: static !important;
          object-fit: contain !important;
        }
      }
    }

    iframe,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
