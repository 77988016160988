@import "../global/mixins";

:root {
	--stickySideMobileHeight: getRem(65);
}

.stickySidebar {
	display: grid;
	grid-template-columns: calc(100% - getRem(340)) getRem(275);
    column-gap: getRem(65);

	@include breakpoint(tablet) {
		grid-template-columns: calc(100% - getRem(240)) getRem(200);
		column-gap: getRem(40);
	}

	@include breakpoint(mobile) {
		display: block;
		max-width: 100%;
		margin-inline: 0;
		padding-inline: getRem(20);
	}

	.sticky {
		position: sticky;
		top: 0;
	}
}

.stickySidebarContent {
	@include breakpoint(mobile) {
		margin-top: var(--stickySideMobileHeight);
	}
}

.stickySidebarSide {
	position: relative;

	@include breakpoint(mobile) {
		position: fixed;
		top: var(--nav-height);
		left: 0;
		width: 100vw;
		height: var(--stickySideMobileHeight);
		overflow: auto;
		z-index: 999;
	}
}

.stickySidebarScrolled {

	& > *:first-child {
		top: var(--nav-height);

		@include breakpoint(mobile) {
			top: 0;
		}
	}

	@include breakpoint(mobile) {
		top: 0;
	}
}

.stickySidebarNav {
    position: sticky;
    top: 0;
	margin: 0;
    padding: getRem(20);
	list-style: none;
    border-top: 6px solid #ffbc0d;
    border-radius: 4px;
	background-color: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);

	@include breakpoint(mobile) {
		display: flex;
		gap: getRem(16);
		padding-inline: getRem(20);
		background-color: #ffbc0d;;
		box-shadow: none;
		overflow: auto;
	}

	li {
		margin-top: 14px;
	}
}

.stickySidebarNavExpanded {
	flex-direction: column;
}

.stickySidebarNavExpandBtn {
	display: none;

	@include breakpoint(mobile) {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		width: getRem(40);
		height: getRem(40);
		margin-inline: auto;
		background-color: #ffbc0d;
		border: 0;
		border-radius: 50%;
		appearance: none;
		transform: translateY(getRem(-20));
		z-index: -1;
	}
}

.stickySidebarNavExpandIcon {
	width: getRem(16);
	margin-bottom: getRem(4);
	height: auto;
}

.stickySidebarNavLink {
	font-size: getRem(16);
	font-weight: 400;
	line-height: 1.2;
	color: #292929;
	padding-block: getRem(5);

	@include breakpoint(mobile) {
		white-space: nowrap
	}

	&:hover {
		color: #000;
		cursor: pointer;
	}
}

.stickySidebarNavLinkActive {
	font-weight: 700;
	color: #db0007;

	&:hover {
		text-decoration: none;
		color: #db0007;
		cursor: default;
	}
}

.stickySidebarMenuToggle {
	background-color: transparent;
	border: none;
	color: var(--text-color);
	cursor: pointer;
	display: inline-block;
	@include font(16, 18);
	font-weight: 400;
	padding: 0;
	text-align: left;
}

.stickySidebarToggleIcon {
	width: getRem(20);
	height: getRem(20);
	background-color: var(--light-grey);
	border: none;
	color: var(--red);
	display: inline-block;
	@include font(16, 20);
	margin-left: getRem(4);
	text-align: center;
}