.page--homepage {
	min-height: 100vh;

  // h2{
  //   font-size: 4.5rem;
  // }
  // .trending h2{
  //   font-size: 2.5rem;
  // }
  @include breakpoint(mobile) {
    --gap: #{getRem(20)};
  }

  width: 100%;

  .featured-container {
    // z-index: 1;
  }

  .featured {
    --image-clip-size: #{getRem(10000)};
    width: 100%;
    position: relative;
    z-index: 2;
    display: block;

    @include breakpoint(desktop) {
      // min-height: getRem(840);
    }

    @include breakpoint(mobile) {
      --image-clip-size: #{getRem(2500)};
      margin-bottom: getRem(60);
    }

    .content {
      z-index: 2;
      color: var(--white);
      padding-top: var(--nav-height);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: auto;

      @include breakpoint(desktop) {
        height: 100%;
      }

      @include breakpoint(tablet) {
        justify-content: flex-start;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -5;
      top: 0;
      left: 0;

      @include breakpoint(mobile-tablet) {
        max-height: getRem(600);
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        display: block;
        background-color: rgba(#232323, 0.3);
        pointer-events: none;
        top: 0;
        left: 0;
      }

      // clip-path: path('M1441.028 966.916c-512.47 0-999.672-34.787-1440.01-97.414l-.03-474.99.04.004V0h1440v966.916z') fill-box;
      // clip-path: path('M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z');
      display: block;
      // background-color: purple;
      clip-path: circle(var(--image-clip-size) at 100% calc(-1 * var(--image-clip-size) + 100%));
      // img{
      //   display: none!important;
      // }
      // img{
      // }
    }

    & .content>.wrapper {
      position: relative;
      display: block;
      margin-top: 0;
      padding-top: 0;

      // background-color: rgba(blue, .25);
      // height: 100%;
      &>.wrapper.top-content {
        // position: relative;
        // padding-top: getRem(62);
        height: auto;
        display: block;

        // background-color: rgba(red, .25);
        @include breakpoint(desktop) {
          margin-bottom: -#{getRem(110)};
          padding-bottom: getRem(210 + 72);
        }

        @include breakpoint(desktop) {
          transform: translateY(getRem(110));
        }

        @include breakpoint(mobile) {
          padding-top: getRem(30);
        }
      }
    }

    .meta-from-cards {
      // display: none;
      position: relative;
      // display: inline-block;
      color: var(--white);
      margin-top: 0;
      padding-top: 0;

      @include breakpoint(mobile-tablet) {
        margin-bottom: 2rem;
      }

      .categoryContainer {
        max-width: getRem(250);
        white-space: nowrap;

        .category {
          max-width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;

          svg {
            flex-shrink: 0;
          }
        }
      }
    }

    a:hover h1 {
      color: var(--yellow);
    }

    .featured-date {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;

      @include breakpoint(desktop) {
        left: 1rem;
      }

      .day {
        font-size: getRem(24);
        @include lineHeightRatio(24, 22);
        display: block;
      }

      .month {
        font-size: getRem(14);
        @include lineHeightRatio(14, 22);
        font-weight: bold;
      }

      @include breakpoint(mobile-tablet) {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        // math to make it 1em from the left of the page
        margin-left: calc((100% - 100vw) / 2 + 1rem);

        .day,
        .month {
          line-height: 1em;
        }

        .day {
          margin-right: 0.25em;
        }
      }

      @include breakpoint(tablet) {
        // display: none;
        // display: block;
        position: relative;
        margin-bottom: getRem(30);
        margin-top: 1rem;
      }

      @include breakpoint(mobile) {
        // display: block;
        position: relative;
        margin-bottom: getRem(68);
        margin-top: 1rem;
      }
    }

    .super {
      margin-bottom: getRem(14);

      span:not(:last-of-type) {

        // margin-right: 1em;
        // padding-right: 1em;
        &::after {
          margin: 0 0.5em;
          content: "•";
        }
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 0.25em;
    }

    // .bottom-cards{
    //   --card-height: #{getRem(160)};
    //   position: absolute;
    //   // left: auto;
    //   // right: auto;
    //   top: calc(100% - var(--card-height));
    // }
    .bottom-cards {
      @include breakpoint(desktop) {
        padding-bottom: getRem(120);
      }

      @include breakpoint(tablet) {
        padding-bottom: getRem(57);
      }

      .wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: var(--gap);

        @include breakpoint(tablet) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint(mobile) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }
    }

    @include breakpoint(mobile) {
      .super.caption {
        letter-spacing: getRem(1.5);
        font-size: getRem(12);
        @include lineHeightRatio(12, 14);
      }
    }
  }

  .wrapper {
    padding: 0 getRem(80);
    position: relative;

    @include breakpoint(mobile-tablet) {
      padding: 0 getRem(24);
    }
  }

  .ais-RangeInput {
    display: none;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: getRem(36);
    margin-top: getRem(26);

    h2 {
      width: 100%;
      line-height: 1em;
      text-align: center;
    }

    a,
    h2 {
      margin: 0;
    }

    a {
      margin-bottom: 0.5rem;
    }

    @include breakpoint(mobile) {
      .btn {
        margin: 0;
      }
    }
  }

  .articles-section {
    grid-column: 1 / span 1;
    padding: 0 getRem(80);
    position: relative;
    text-align: center;

    &:after {
      content: '';
      width: 100%;
      height: calc(100% + 300px);
      background-color: #F7F7F7;
      position: absolute;
      top: -300px;
      left: 0;
      z-index: 0;
    }

    @include breakpoint(mobile-tablet) {
      padding: 0;
    }

    .cards {
      text-align: left;
    }
  }

  .trending {
    margin-top: getRem(120);
    position: relative;
    z-index: 1;

    @include breakpoint(mobile-tablet) {
      margin-top: getRem(80);
    }

    @include breakpoint(mobile) {
      padding-top: 0;
      margin-top: getRem(40);
    }

    .wrapper {
	  max-width: getRem(1400);
      padding: 0 getRem(40);
      position: relative;

      @include breakpoint(mobile-tablet) {
        padding: 0 getRem(8);
      }
    }

    &__inner {
      width: 100%;
      height: 100%;
    }

    .cards {
      position: relative;

      .swiper-row {
        display: flex;
        align-items: center;
      }

      .swiper {
        width: 100%;
      }

      .swiper-slide {
        max-width: getRem(1336);
        display: flex;
        padding: getRem(20) getRem(40);

        @include breakpoint(mobile-tablet) {
          padding: getRem(16) getRem(16);
        }

        &>div {
          width: 100%;
          min-height: getRem(350);
        }
      }

      .swiper-navigation-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: getRem(32);
      }

      .pagination-container {
        margin: auto;
        display: flex;
        align-items: center;

        button {
          margin-left: getRem(4);
        }
      }

      .pause-button {
        width: getRem(36);
        height: getRem(36);
        background: none;
        border: 0;
        cursor: pointer;
        flex-shrink: 0;
        padding: 0;
        position: relative;

        svg {
          width: 100%;
          height: 100%;
        }

        span {
          width: 100%;
          height: 100%;
          @include font(2, 2);
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: getRem(12);
        background: none;
        border: none;
        flex-shrink: 0;
        margin: 0;
        padding: 0;
        position: relative;

        &:before,
        &:after {
          display: none;
        }

        span {
          width: 100%;
          height: 100%;
          @include font(2, 2);
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
      }

      .swiper-button-next {
        svg {
          transform: rotate(180deg);
        }
      }

      .swiper-pagination-bullets {
        display: flex;
        justify-content: center;
        margin: auto;
        // margin-top: getRem(32);

        .swiper-pagination-bullet {
          width: getRem(24);
          height: getRem(4);
          // background: #D6D6D6;
          border-radius: 0;

          &:not(:first-child) {
            margin-left: getRem(6);
          }

          &.swiper-pagination-bullet-active {
            background: var(--yellow);
          }
        }
      }

      .trending {
        grid-template-columns: repeat(2, 50%);
        margin-top: 0;
      }

      .card-body {
        padding: getRem(40) getRem(88);
        position: relative;

        @include breakpoint(mobile-tablet) {
          padding: getRem(16);
        }
      }

      .content {
        &>div:last-child {
          padding: 0;

          @include breakpoint(mobile) {
            padding: getRem(18);
          }

          p:first-child {
            margin: 0;
          }
        }
      }

      .trendingNumber {
        width: getRem(48);
        height: getRem(48);
        background: var(--red);
        border-radius: 50%;
        position: absolute;
        top: getRem(20);
        left: getRem(20);
        text-align: center;

        @include breakpoint(mobile-tablet) {
          width: getRem(36);
          height: getRem(36);
          top: getRem(16);
          left: auto;
          right: getRem(16);
        }

        p {
          color: var(--white);
          display: inline-block;
          @include font(30, 48);
          font-weight: bold;
          margin: 0;
          position: relative;

          @include breakpoint(mobile-tablet) {
            @include font(21, 36);
          }
        }

        span {
          @include font(16, 24);
          display: inline-block;
          vertical-align: top;
          margin-top: getRem(8);
          position: absolute;
          top: getRem(-2);
          left: getRem(-10);

          @include breakpoint(mobile-tablet) {
            @include font(14, 14);
            left: getRem(-8);
          }
        }
      }

      .article-link {
        height: 100%;
        aspect-ratio: auto;

        @include breakpoint(mobile) {
          aspect-ratio: 4/3;
        }

        h3 {
          @include font(32, 40);

          @include breakpoint(mobile-tablet) {
            @include font(20, 24);
          }
        }
      }
    }
  }

  .articles-container {
    position: relative;
    z-index: 1;

    .title {
      display: block;

      h2 {
        width: 100%;
        text-align: center;
      }
    }

    .cards {
      display: grid;
      gap: var(--gap);
      align-items: flex-start;
      grid-template-columns: repeat(3, 1fr);
      padding-bottom: getRem(50);

      @include breakpoint(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint(mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .cards-grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: getRem(24) getRem(24);
      grid-template-rows: 1fr auto auto auto;

      &>a:first-of-type {
        grid-row: 1 / span 8;

        h3 {
          font-size: getRem(40);
          @include lineHeightRatio(40, 48);
        }
      }

      &>a:not(:first-of-type) {
        grid-column: 2;
      }

      @include breakpoint(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

      .iconImageContainer {
        min-height: 16vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--yellow);

        &.happyMealContainer {
          background: #FFFFFF;
          border: 2px solid var(--yellow);
        }

        @include breakpoint(tablet) {
          min-height: 21vw;
        }

        @include breakpoint(mobile) {
          min-height: 48vw;
        }
      }
    }

    .no-results-msg {

      // font-weight: 700;
      a {
        color: var(--red);
        font-weight: 700;
      }
    }
  }

  .newest-old .cards {
    display: grid;
    grid-template-columns: 14fr 9fr;
    gap: calc(var(--gap) * 0.5) var(--gap);
    grid-template-rows: 1fr auto auto auto;

    &>a:first-of-type {
      grid-row: 1 / span 8;

      h3 {
        font-size: getRem(40);
        @include lineHeightRatio(40, 48);
      }
    }

    &>a:not(:first-of-type) {
      grid-column: 2;
    }
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: getRem(24);
    position: relative;

    .dropdown-options {
      position: relative;

      @include breakpoint(mobile) {
        position: static;
      }

      .toggle-dropdown {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          font-weight: 700;
          font-size: getRem(18);
          line-height: getRem(24);
        }

        svg {
          width: getRem(16);
          height: auto;
          margin-top: getRem(-6);
          margin-left: getRem(8);
          overflow: visible;
        }
      }

      .filters-container {
        width: getRem(450);
        background: #FFFFFF;
        box-shadow: 0 0 getRem(28) rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: getRem(24);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        display: none;

        @include breakpoint(mobile) {
          left: 50%;
          transform: translateX(-50%);
          max-width: 90vw;
        }

        &.show {
          display: block;
        }

        .top-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: getRem(16);
        }

        p {
          font-weight: 700;
          font-size: getRem(18);
          line-height: getRem(24);
          margin: 0;
        }

        button {
          background: none;
          border: none;
          color: var(--red);
          cursor: pointer;
          font-size: getRem(14);
          font-weight: 700;
          line-height: getRem(24);
          padding: 0;

          &:not(:first-child) {
            margin-left: getRem(16);
          }

          &:focus,
          &:hover {
            border-bottom: 1px solid black;
          }

          &.disabled {
            color: var(--grey);
            font-weight: normal;
            pointer-events: none;
            opacity: 0.6;
          }
        }
      }
    }

    .preferences-container {
      width: 100%;
    }

    .ais-RefinementList-list {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        border-top: 1px solid rgba(#2D2D2D, .2);

        label {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: getRem(16) 0;

          div {
            display: flex;
            align-items: center;
          }

          span {
            color: #292929;

            &.ais-RefinementList-labelText {
              font-weight: 700;
              font-size: getRem(16);
              line-height: getRem(18);
            }

            &.type-label {
              font-size: getRem(12);
              line-height: getRem(18);
              letter-spacing: getRem(1.75);
              margin-left: getRem(16);
              text-transform: uppercase;
            }

            &.ais-RefinementList-count {
              display: none;
            }
          }

          input {
            margin-right: 0;
          }
        }

        .no-filters-message {
          font-size: getRem(16);
          font-weight: normal;
          line-height: getRem(18);
          margin-top: getRem(16);

          .bold {
            font-weight: bold;
          }

          a {
            font-size: getRem(16);
            line-height: getRem(18);
          }
        }

        &:last-child {
          label {
            padding-bottom: 0;
          }
        }
      }
    }

    .checkbox-option {
      display: flex;
      align-items: center;
      margin-left: getRem(40);
    }

    a {
      color: #DB0007;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      // margin-left: auto;
      margin-left: getRem(40);
    }
  }

  .ais-InfiniteHits {
    display: flex;
    flex-direction: column;

    .ais-InfiniteHits-list {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 12px));
      grid-gap: getRem(24);
      padding: 0;
      list-style: none;

      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column;
      }

      .card-body {
        justify-content: flex-start;
        padding: getRem(16);

        .cardBottom {
          margin-top: auto;
          padding-bottom: 0;
        }
      }

      // & > div {
      //   @include breakpoint(mobile) {
      //     &:not(:first-child) {
      //       margin-top: getRem(24);
      //     }
      //   }
      // }

      li,
      div {
        text-align: left;
      }

      li {
        &>div {
          height: 100%;
        }
      }

      h3 {
        @include font(20, 24);

        a {
          color: var(--black);
        }
      }
    }
  }

  .buttons-container {
    margin-top: getRem(36);
  }

  // stamps
  .stamps {
    // float: right;
    margin-bottom: getRem(6);

    ul {
      margin: 0 0 0 getRem(10) !important;
      padding: 0 !important;

      @include breakpoint(mobile) {
        margin-left: 0 !important;
      }
    }

    li {
      margin: 0 !important;
      padding: 0 !important;

      >div {
        margin: 0 0 0 getRem(10) !important;

        @include breakpoint(mobile) {
          margin-left: 0 !important;
          margin-right: getRem(10);
        }
      }
    }

    .stamp-label {
      display: none !important;
    }

    .creative-effectiveness,
    .shamelessly-stolen {
      display: none;
    }
  }

  .cardBottom {
    border-top: 1px solid rgba(#ADADAD, .5);
    margin-top: auto;

    // & > div {
    //   margin-top: getRem(8);
    // }
  }

  .country {
    svg path {
      fill: #6F6F6F;
    }
  }

  .titleOnlyCard {
    .stamps ul {
      margin-left: 0 !important;

      li>div:first-child {
        margin-left: 0 !important;
      }
    }
  }

  .trendingCards {
    h3 {
      margin-bottom: getRem(16);
    }

    .cardBottom {
      border-top: none;
      padding: 0;
      margin-top: getRem(16);
    }

    .superText {
      // display: block;
      margin-top: 0 !important;
    }

    // .date {
    //   display: none;
    // }

    .category {
      margin-left: 0 !important;
    }

    .stamp {
      margin-left: 0;
    }

    .stamps {
      float: none;

      ul {
        justify-content: flex-start;
        margin-left: 0 !important;

        li:first-child>div {
          margin-left: 0 !important;
        }
      }

      .stamp-label {
        display: inline-block !important;
        @include font(16, 24);
        margin-left: getRem(8);
      }
    }

    &:last-of-type {
      .metaHover {
        top: auto;
        bottom: 0;
      }
    }
  }

  .newest {
    .ais-InfiniteHits {
      .ais-InfiniteHits-list {
        grid-template-columns: repeat(3, calc(33.33% - (4rem / 3)));
        grid-gap: getRem(32);
        margin-top: getRem(48);

        @include breakpoint(tablet) {
          grid-template-columns: repeat(2, calc(50% - 2rem));
        }

        .card-body {
          padding: 0;
        }

        h3 {
          @include font(24, 32);
          margin: 0;
        }

        p {
          margin: 0;
        }

        .cardBottom {
          padding-bottom: getRem(8);

          .superText {
            & > *:not(:last-child) {
              margin-right: getRem(8);
            }
          }
        }
      }

      .ais-InfiniteHits-loadMore {
        margin: getRem(56) auto 0;
      }
    }
  }

  .bottom-banner {
    display: flex;
    padding: getRem(90) 0;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: calc(100% + 40px);
      background: url(/../../images/homepage-cta-bg.svg) 50% 100% no-repeat;
      background-size: 100% 100%;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    &.no-content {
      padding: getRem(45) 0;

      &:before {
        display: none;
      }
    }

    .wrapper {
      position: relative;
      z-index: 1;
    }

    .banner-container {
      width: 100%;
      max-width: getRem(820);
      background-color: var(--white);
      background-image: url('/../../images/homepage-bottom-bg.svg');
      background-size: auto 100%;
      background-position: 100% 50%;
      background-repeat: no-repeat;
      box-shadow: 0 getRem(8) getRem(24) rgba(0, 0, 0, 0.12);
      margin: auto;
      overflow: hidden;
      padding: getRem(32) getRem(200) getRem(32) getRem(40);

      @include breakpoint(mobile) {
        padding: getRem(24);
        background-position: -350% 50%;
      }
    }

    h2 {
      @include font(32, 40);
      margin: 0;
    }

    a {
      display: inline-block;
      @include font(16, 26);
      border-bottom: 1px solid var(--red);
      margin-top: getRem(16);
    }
  }
}

.parallax-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // transition: all 0s linear;
  // will-change: transform;
  // transform: translate3d(0, 0, 0);
  // transition: transform 0.05s linear;
}

.reactionIcon {
  width: getRem(18);
  height: getRem(18);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: var(--text-color);
  padding: 0 !important;

  svg {
    max-height: 60%;
  }

  &.thumbsUp {
    background-color: var(--reaction-thumbsUp);
  }

  &.thumbsDown {
    background-color: var(--reaction-thumbsDown);
  }

  &.thumbsWantMore {
    background-color: var(--reaction-question);
  }

  &.thumbsShamelesslySteal {
    background-color: var(--reaction-check);
  }
}