.ais-InfiniteHits-loadMore,
.btn {
  --radius: #{getRem(3)};
  position: relative;
  padding: 0.5em 3em;
  background-color: var(--yellow);
  color: var(--black);
  cursor: pointer;
  border-radius: var(--radius);
  display: inline-block;
  margin: 0 1em 1em 0;
  border: 0 solid transparent;
  transition: transform 0.33s md-ease(ease-in-out), color 0.33s md-ease(ease-in-out);
  font-weight: bold;
  font-size: 1.25rem;
  overflow: hidden;
  // box-shadow: 2px 4px 6px black;
  background-color: transparent;
  z-index: 1;
  user-select: none;

  &.small {
    font-size: getRem(14);
  }

  &.ais-InfiniteHits-loadMore,
  &.narrow {
    padding: 0.5em 1em;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 4px 2px #006bae;
  }

  &::before {
    width: 100%;
    height: 100%;
    z-index: -2;
    content: "";
    background-color: var(--yellow);
    border-radius: var(--radius);
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rebeccapurple;
    border-radius: var(--radius);
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--red);
    transform: translate3d(-101%, 0, 0);
    transition: transform 0.33s md-ease(ease-in-out);
  }

  &:hover,
  &:focus {
    color: var(--white);
    text-decoration: none;

    &::after {
      // transform: translate3d(-2em, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  &.has-icon:hover::after,
  &.narrow:hover::after {
    transform: translate3d(0, 0, 0);
  }

  &.secondary {
    color: var(--white);

    &::before {
      background-color: var(--red);
    }

    &::after {
      background-color: var(--yellow);
    }

    &:hover {
      color: var(--black);
    }
  }

  &.dark {
    color: var(--white);

    &::before {
      background-color: var(--black);
    }

    &::after {
      background-color: var(--red-dark);
    }

    &:hover {
      color: var(--white);
    }
  }

  // @media screen and (max-width: 800px) {
  //     background-color: blue;
  //     &:hover {
  //         background-color: red;
  //     }
  // }
  .icon {
    display: block;
    width: 2em;
    height: 100%;
    z-index: 5;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    background-size: 20px 20px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 100%;
      max-height: 50%;
    }
  }

  &.ais-InfiniteHits-loadMore--disabled {
    display: none;
  }

  &.follow-btn {
    width: auto;
    background: #FFFFFF;
    // border: 1px solid #000000;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;

    &:before {
      background-color: var(--red);
    }

    &:after {
      background-color: var(--yellow);
    }

    span {
      background: transparent;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 1.25rem;
      padding: getRem(12) getRem(18);
      position: relative;
      transition: all 0.33s;
      z-index: 1;
    }

    svg {
      // margin-left: getRem(36);
      position: absolute;
      top: 50%;
      left: getRem(18);
      transform: translateY(-50%);
      z-index: 0;

      g {
        fill: #FFFFFF;
        transition: all 0.33s;
      }
    }

    &:hover {
      // border: 1px solid #FFFFFF;

      span {
        color: #000000;
      }

      svg g,
      svg path {
        fill: #000000;
      }
    }

    @include breakpoint(mobile) {
      margin: 0 auto getRem(32) 0;
    }

    &.isFollowing {
      &:hover {
        svg path {
          fill: #000000;
          transition: all 0.33s;
        }
      }
    }

    &:disabled {
      cursor: inherit;

      &:hover {
        &:after {
          transform: translate3d(-101%, 0, 0);
        }

        span {
          color: #FFFFFF;
        }

        svg path,
        svg g {
          fill: #FFFFFF;
        }
      }
    }

    &.has-icon {
      span {
        padding-right: getRem(18);
        padding-left: getRem(46);
      }
    }
  }

  &.has-icon {
    span {
      padding-right: getRem(52);
    }
  }
}

.ais-InfiniteHits-loadMore {
  &:last-of-type {
    margin: 0 0 1em;
  }
}

header .btn {
  @include breakpoint(mobile) {
    width: 100%;
  }
}

.share-button-container {
  position: relative;

  &>button {
    border-radius: getRem(3);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: getRem(12) getRem(18);
    position: relative;
    margin: auto;

    &:before {
      background-color: var(--red);
    }

    &:after {
      background-color: var(--yellow);
    }

    span {
      width: 100%;
      height: 100%;
      @include font(2, 2);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &.show-label {
        width: auto;
        height: auto;
        @include font(20, 24);
        position: static;
        opacity: 1;
        z-index: 0;
      }
    }

    svg {
      path {
        fill: var(--black);
        stroke: var(--black);
      }
    }
  }

  .share-list-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;

    &.show {
      display: block;
      z-index: 100;
    }

    @include breakpoint(mobile) {
      width: 100%;
      max-width: 80vw;
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      right: auto;
      transform: translate(-50%, -50%);

      .modal-background {
        width: calc(100vw + 2px);
        height: calc(100vh + 2px);
        background-color: rgba(0, 0, 0, 0.8);
        display: inline-block !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
      }
    }

    .modal-background {
      display: none;
    }

    .closeBtn {
      width: getRem(36);
      height: getRem(36);
      background: var(--yellow);
      border: none !important;
      border-radius: getRem(4) getRem(4) 0 0;
      display: none;
      padding: getRem(8);
      position: absolute;
      bottom: 100%;
      right: 0;

      @include breakpoint(mobile) {
        display: inline-block;
      }

      span {
        width: 100%;
        height: 100%;
        font-size: getRem(2);
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      svg {
        path {
          fill: var(--black);
        }
      }
    }
  }

  ul.share-list {
    background: var(--white);
    box-shadow: 0 getRem(1) getRem(9) getRem(2) rgba(0, 0, 0, 0.1);
    // border-radius: 0 0 getRem(4) getRem(4);
    // display: block;
    list-style: none;
    margin: 0;
    padding: getRem(20);
    position: relative;
    z-index: 1;

    @include breakpoint(mobile) {}

    li {
      display: flex;
      align-items: center;
      white-space: nowrap;

      div {
        display: flex;
        align-items: center;
      }

      svg {
        margin-right: getRem(8);

        path {
          stroke: var(--black);
        }
      }

      span {
        @include font(16, 20);
      }

      a {
        color: var(--black);
        display: flex;
        align-items: center;
        font-weight: 400;
      }

      button {
        margin: 0;

        span {
          font-weight: 400;
        }

        &:hover,
        &:focus {
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ms-share-button {
  position: relative;

  .teams-share-button {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    a {
      width: 100%;
      display: block;
    }

    &:hover {
      &+.visible-button-label {
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .visible-button-label {
    display: flex;
    align-items: center;
  }
}

.email-share-button {
  button {
    span {
      margin-left: 0 !important;
    }
  }
}

.card-bookmark {
  .isBookmarked {
    svg path {
      fill: var(--yellow) !important;
    }
  }
}