@import "../global/mixins";

.gallery {
    --button-size: 1.5rem;
    max-width: getRem(690);
    background-color: var(--light-grey);
    margin-top: getRem(80);
    margin-bottom: getRem(80);
    figure {
        padding: 0;
        margin: 0;
        // min-height: getRem(513);
    }
    figcaption {
        display: block;
        margin-top: 1em;
        color: var(--text-light);
        @include font(16, 26); // TODO: See if this matches in style guide
        min-height: getRem(115);
        @include breakpoint(mobile) {
            min-height: getRem(150);
        }
    }

    :global .swiper-container {
        position: relative;
        padding: getRem(24) getRem(24) 0;
    }

    :global .swiper-button-prev {
        bottom: 0;
        right: 2.5rem;
        top: unset;
        left: unset;
        // background-color: rebeccapurple;
        // display: none;
        width: var(--button-size);
        height: var(--button-size);
        display: block;
        &::after {
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGcgZmlsbD0iIzI5MjkyOSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgIDxwYXRoIGQ9Ik05LjcwOSAxLjRsLS41My0uNTMtOC4xNTUgOC4xNTUgMS4yMzcgMS4yMzggNy40NDgtNy40NDhhMSAxIDAgMDAwLTEuNDE1eiIvPgogICAgPHBhdGggZD0iTTIuMjYgNy43ODhMMS4wMjUgOS4wMjVsOC4xNTUgOC4xNTUuNTMtLjUzYTEgMSAwIDAwMC0xLjQxNEwyLjI2IDcuNzg4eiIvPgogIDwvZz4KPC9zdmc+");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    :global .swiper-button-next {
        bottom: 0;
        right: 1rem;
        top: unset;
        left: unset;
        // display: none;
        width: var(--button-size);
        height: var(--button-size);
        display: block;
        &::after {
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGcgZmlsbD0iIzI5MjkyOSIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgIDxwYXRoIGQ9Ik05LjcwOSAxLjRsLS41My0uNTMtOC4xNTUgOC4xNTUgMS4yMzcgMS4yMzggNy40NDgtNy40NDhhMSAxIDAgMDAwLTEuNDE1eiIvPgogICAgPHBhdGggZD0iTTIuMjYgNy43ODhMMS4wMjUgOS4wMjVsOC4xNTUgOC4xNTUuNTMtLjUzYTEgMSAwIDAwMC0xLjQxNEwyLjI2IDcuNzg4eiIvPgogIDwvZz4KPC9zdmc+");
            transform: rotate(180deg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .thumbnailSwiper {
        padding-bottom: getRem(25);
        &::after {
            position: absolute;
            content: "";
            width: getRem(60);
            height: 100%;
            top: 0;
            right: 0;
            z-index: 5;
            pointer-events: none;
            background: linear-gradient(
                to right,
                transparent,
                var(--light-grey) 90%
            );
        }
    }
    :global .swiper-container-thumbs .swiper-slide > div {
        transition: transform 0.33s md-ease(ease-in-out),
            box-shadow 0.33s md-ease(ease-in-out);
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: getRem(5);
            background-color: var(--yellow);
            // transform: translate3d(0px #{getRem(5)} 0px);
            transform: translate3d(0, #{getRem(5)}, 0);
            transition: transform 0.33s md-ease(ease-in-out);
        }
    }
    :global .swiper-container-thumbs .swiper-slide-thumb-active > div {
        // box-shadow: 0px 0px 10px 5px rgba(#006bae, 0.5);
        // box-shadow: 0 5px 15px 2.5px rgba(0, 0, 0, 0.3);
        // transform-origin: center;
        // transform: scale(1.05);
        // z-index: 5;
        &:after {
            transform: translate3d(0, 0, 0);
        }
    }

    & > div {
        &:first-child {
            .swiper-slide {
                padding: getRem(16);
            }
        }
    }
}
