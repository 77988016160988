@import "../global/mixins";

.page--bookmark {
  margin-top: calc(var(--nav-height));
  padding: getRem(48) 0 getRem(78);

  h1 {
    margin-top: 0;
  }

  .columns-container {
    display: flex;

    @include breakpoint(mobile-tablet) {
      flex-wrap: wrap;
    }
  }
  .folders-container {
    width: 100%;
    margin-bottom: auto;
    border-radius: getRem(6);

    h2 {
      font-size: getRem(14);
      font-weight: 400;
      letter-spacing: getRem(1.75);
      line-height: getRem(18);
      margin: 0;
      text-transform: uppercase;
    }

    ul.folders-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: getRem(8);
      list-style: none;
      padding: 0;

      & > li {
        width: calc(100% / 6 - 8px);
        margin-bottom: getRem(8);
        position: relative;
        z-index: 1;

        @media (max-width: 1200px) {
          width: calc(100% / 5 - 8px);
        }

        @include breakpoint(tablet) {
          width: calc(100% / 4 - 8px);
        }

        @include breakpoint(mobile) {
          width: 100%;
        }

        &:first-child {
          margin-top: 0;
        }

        &.options-open {
          z-index: 10;
        }

        a {
          background: #F7F7F7;
          border-radius: getRem(6);
          display: block;
          padding: getRem(24) getRem(16) getRem(16);

          @include breakpoint(mobile) {
            outline: 1px solid #ADADAD;
          }

          &:hover,
          &:focus {
            outline: 2px solid black;
            text-decoration: none;
          }
        }

        h3,
        .edit-name {
          font-size: getRem(20);
          font-weight: bold;
          color: #292929;
          letter-spacing: 0;
          line-height: getRem(20);
          margin: 0;
        }

        .edit-name {
          max-width: 100%;
          background: none;
          border: none;
          margin: 0;
          padding: 0;
        }

        p {
          font-size: getRem(16);
          font-weight: 400;
          color: #292929;
          letter-spacing: 0;
          line-height: getRem(26);
          margin: getRem(6) 0 0;
        }

        .folder-options {
          position: absolute;
          top: getRem(12);
          right: getRem(12);
          z-index: 1;

          &-inner {
            position: relative;
          }

          .toggle-btn {
            height: 25px;
            width: 25px;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0;

            // &:hover {
            //   outline: 1px solid black;
            // }

            span {
              width: 25px;
              height: 7px;
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -100;
            }
          }

          ul.options-list {
            background: white;
            border-radius: 2px;
            box-shadow: 0 5px 10px rgba(0,0,0,.1);
            display: none;
            list-style: none;
            margin: 0;
            padding: getRem(14) getRem(16);
            position: absolute;
            top: 100%;
            left: 0;

            @include breakpoint(mobile) {
              left: auto;
              right: 0;
            }

            &.show {
              display: block;
            }

            li {
              margin-top: 16px;
              padding: 0;

              &:first-child {
                margin-top: 0;
              }
            }

            button {
              background: none;
              border: none;
              font-size: 14px;
              font-weight: 600;
              color: #DB0007;
              cursor: pointer;
              letter-spacing: 0;
              line-height: 24px;
              padding: 0;
              white-space: nowrap;
            }
          }
        }

        &.active {
          a {
            background: #292929;

            &:hover,
            &:focus {
              outline: 2px solid #F7F7F7;
            }
          }

          h3, p, .edit-name {
            color: white;
          }

          .folder-options {
            .toggle-btn {
              &:hover {
                outline: 1px solid white;
              }
              svg circle {
                stroke: white;
              }
            }
          }
        }

        &:nth-child(6n + 6) {
          ul.options-list {
            left: auto;
            right: 0;

            @include breakpoint(mobile) {
              left: 0;
              right: auto;
            }
          }
        }
      }
    }

    .new-folder-btn {
      border: getRem(1) solid #ADADAD;
      box-sizing: border-box;
      border-radius:getRem(6);

      button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        border-radius:getRem(6);
        box-sizing: border-box;
        color: #292929;
        cursor: pointer;
        font-size: getRem(14);
        font-weight: bold;
        letter-spacing: 0;
        line-height: getRem(24);
        padding: getRem(16) getRem(24);
        text-align: left;

        &:hover,
        &:focus {
          outline: 2px solid black;
          text-decoration: none;
        }

        .plus-icon {
          font-size: getRem(48);
          font-weight: 400;
          line-height: getRem(24);
          color: #2D2D2D;
          display: block;
        }
      }
    }
  }
  .bookmarks-container {
    background: #F7F7F7;
    border-radius: 6px;
    //margin: getRem(16) 0 auto;
    padding: getRem(10) getRem(40) getRem(100);

    @include breakpoint(mobile-tablet) {
      padding: getRem(10) getRem(24) getRem(40);
    }

    @include breakpoint(mobile) {
      padding: getRem(10) 0 getRem(40);
    }

    .folder-options {
      display: flex;

      &-inner {
        margin-left: auto;
        position: relative;
      }

      .toggle-btn {
        background: none;
        border: none;
        cursor: pointer;
        svg, span {
          display: inline-block;
          vertical-align: middle;
        }
        span {
          font-size: 14px;
          color: #6F6F6F;
          letter-spacing: 0;
          line-height: 24px;
          margin-left: getRem(8);
        }
      }

      ul.options-list {
        background: white;
        border-radius: 2px;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        display: none;
        list-style: none;
        margin: 0;
        padding: getRem(14) getRem(16);
        position: absolute;
        top: 100%;
        left: 0;

        &.show {
          display: block;
        }

        li {
          margin-top: 16px;
          padding: 0;

          &:first-child {
            margin-top: 0;
          }
        }

        button {
          background: none;
          border: none;
          font-size: 14px;
          font-weight: 600;
          color: #DB0007;
          letter-spacing: 0;
          line-height: 24px;
          padding: 0;
          white-space: nowrap;
        }
      }
    }

    .section-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: getRem(18);

      h2 {
        font-size: getRem(48);
        font-weight: 600;
        color: #111111;
        letter-spacing: 0;
        line-height: getRem(56);
        margin: 0;
      }

      .sort {
        p {
          font-size: 14px;
          color: #292929;
          display: inline-block;
          vertical-align: middle;
          letter-spacing: 1.75px;
          line-height: 18px;
          margin: 0;
          text-transform: uppercase;
        }

        select {
          height: getRem(36);
          width: getRem(203);
          display: inline-block;
          vertical-align: middle;
          margin-left: getRem(4);
        }
      }

      @include breakpoint(mobile) {
        flex-wrap: wrap;

        .sort {
          width: 100%;
          margin-top: getRem(12);
        }
      }
    }
  }

  #results {
    display: flex;

    @include breakpoint(mobile) {
      flex-wrap: wrap;
    }

    aside {
      width: getRem(240);
      //max-width: getRem(240);

      @include breakpoint(mobile) {
        width: 100%;
      }

      h3 {
        font-size: 21px;
        font-weight: 400;
        color: #292929;
        letter-spacing: 0;
        line-height: 26px;
      }
    }

    .accordion {
      border-top: 1px solid rgba(45, 45, 45, 0.2);
      border-bottom: 1px solid rgba(45, 45, 45, 0.2);
      padding: getRem(16) 0;

      @include breakpoint(mobile) {
        margin-top: getRem(16);
      }

      .accordion__heading,
      .accordion__button {
        padding: 0;
      }

      .accordion__button {
        height: getRem(24);
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: normal;
        text-transform: none;
      }

      .accordion__panel {
        margin-bottom: getRem(18);
      }
    }

    .react-datepicker__input-container input {
      background-color: white;
    }

    .select-group {
      margin-top: getRem(18);

      select {
        width: 100%;
        height: getRem(48);
        background-color: white;
      }
    }

    form {
      .btn {
        width: 100%;
        height: getRem(45);
        padding: getRem(12);
      }
    }

    .results-count {
      color: #292929;
      font-style: normal;
      font-size: getRem(20);
      line-height: getRem(24);

      span {
        font-weight: bold;

        &.red {
          color: var(--red);
        }
      }
    }

    .card-container {
      display: none;

      &:not(:first-child) {
        margin-top: getRem(24);
      }

      &.show {
        display: block;
      }
    }

    .no-results-msg {
      font-size: getRem(20);
      line-height: getRem(24);
    }

    .cards {
      width: calc(100% - 240px);
      //max-width: calc(100% - 240px);
      padding-left: getRem(80);

      @include breakpoint(mobile) {
        width: 100%;
        padding-left: 0;
        margin-top: getRem(40);
      }

      .card {
        height: auto;
        box-shadow: 0 0 28px 0 rgba(0,0,0,0.10);
        border-radius: 0 0 6px 6px;

        .card-body {
          height: auto;
        }
      }
    }

    .buttons-container {
      width: 100%;
      display: flex;
      margin-top: getRem(32);

      .btn {
        margin: auto;
      }
    }
  }

  .dialog-layer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    &-inner {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
    }

    .background {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .dialog-box {
      background: white;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: auto;
      padding: getRem(24) getRem(32);
      position: relative;
      z-index: 1;

      h2 {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 32px;
      }

      form {
        display: flex;
        flex-wrap: wrap;

        div {
          width: 100%;
        }

        input {
          height: getRem(48);
          background: #F7F7F7;
          border: 1px solid #979797;
          border-radius: 2px;
          font-size: 14px;
          color: #292929;
          //letter-spacing: 1.75px;
          line-height: 18px;
          max-width: getRem(468);
          margin-bottom: 0;
        }

        button {
          cursor: pointer;
          margin: getRem(36) auto 0;
        }
      }

      .cancel {
        background: none;
        border: none;
        font-size: 14px;
        color: var(--red);
        cursor: pointer;
        letter-spacing: 0;
        line-height: 24px;
        margin: getRem(8) auto 0;
      }
    }
  }
}
