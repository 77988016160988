// 'font-display: block;' is bad for SEO, but that's not a concern here, so let's make it look better for the end experience.

$font-display: block;
@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: normal;
    font-weight: 400;
    src: url("/fonts/Speedee_W_Rg.woff2") format("woff2");
}

@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: normal;
    font-weight: 400;
    font-style: italic;
    src: url("/fonts/Speedee_W_It.woff2") format("woff2");
}

@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: bold;
    font-weight: 700;
    src: url("/fonts/Speedee_W_Bd.woff2") format("woff2");
}

@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: bold;
    font-weight: 700;
    font-style: italic;
    src: url("/fonts/Speedee_W_BdIt.woff2") format("woff2");
}

@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: lighter;
    font-weight: 100;
    src: url("/fonts/Speedee_W_Lt.woff2") format("woff2");
}

@font-face {
    font-family: "Speedee";
    font-display: $font-display;
    // font-weight: lighter;
    font-style: italic;
    font-weight: 100;
    src: url("/fonts/Speedee_W_LtIt.woff2") format("woff2");
}
