.profile-page {
  .profile-container {
    background: #FFFFFF;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.2);
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    padding: getRem(56) getRem(104);

    @media (max-width: 800px) {
      display: block;
      padding: getRem(24);
    }

    &.employee-type-form-view {
      max-width: getRem(600);
      margin: auto;
      padding: getRem(41) getRem(54);

      p {
        @include font(16, 26);
      }

      h2 {
        @include font(28, 32);
      }

      label {
        background: var(--light-grey);
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        margin-bottom: getRem(24);
        padding: getRem(24);

        span {
          @include font(32, 40);
          font-weight: 700;
          text-transform: none;
        }

        input {
          margin: auto 0 !important;
        }
      }
    }
  }

  &>.wrapper {
    padding: 0 12.2vw;

    @media (min-width: 1441px) {
      padding: 0 getRem(176);
    }

    @media (max-width: 1099px) {
      padding: 0;
    }

    @media (max-width: 800px) {
      padding: 0 4vw;
    }
  }

  .left-content {
    width: getRem(160);
    flex-shrink: 0;

    @media (max-width: 800px) {
      width: 100%;
    }

    ul {
      list-style: none;
      margin-top: getRem(36);
      padding: 0;

      li {
        &:not(:first-child) {
          margin-top: getRem(12);
        }

        @media (max-width: 800px) {
          display: inline-block;
          vertical-align: middle;

          &:not(:first-child) {
            margin-top: 0;
            margin-left: getRem(8);
          }
        }
      }

      button {
        background: none;
        border: none;
        cursor: pointer;
        @include font(20, 25);
        padding: 0;

        &.active {
          font-weight: 700;
          border-bottom: getRem(3) solid var(--red);
        }

        @media (max-width: 800px) {
          border-bottom: getRem(3) solid transparent;

          &.active {
            border-color: var(--red);
          }
        }
      }
    }
  }

  .right-content {
    width: 100%;
    margin-left: getRem(68);

    @media (max-width: 800px) {
      width: 100%;
      border-top: 1px solid rgba(45, 45, 45, 0.2);
      margin-top: getRem(36);
      margin-left: 0;
      padding-top: getRem(36);
    }

    .header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: var(--red);
        @include font(14, 14);
        font-weight: 700;
      }
    }

    input[type="text"] {
      padding: getRem(10) getRem(16);
    }

    #market-select-container,
    .selects-container {
      display: flex;
      flex-direction: column;

      select {
        padding-top: getRem(10);
        padding-bottom: getRem(10);

        &:not(:first-child) {
          margin-top: getRem(4);
        }
      }
    }

    #initiatives {
      display: block;
      margin-top: getRem(8);
    }

    .edit {
      border-top: 1px solid rgba(45, 45, 45, 0.2);
      margin-top: getRem(24);
      padding: getRem(24) 0;
    }

    .btn {
      width: 100%;
      margin-bottom: getRem(24);
    }
  }

  .profile-msg {
    margin: 0 0 getRem(40);
  }

  .profile-header {
    background-color: var(--light-grey);
    padding: getRem(40) getRem(40) 0;

    @include breakpoint(mobile) {
      padding: getRem(20) getRem(20) 0;
    }

    &-inner {
      border-bottom: 1px solid rgba(#2D2D2D, .2);
      padding-bottom: getRem(24);
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      font-weight: 700;
      font-size: getRem(24);
      line-height: getRem(32);
      margin: 0;
    }

    button {
      border: none;
      color: var(--red);
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      padding: 0;
    }

    p {
      font-size: getRem(16);
      line-height: getRem(26);
      margin: getRem(8) 0 0;

      &.light {
        color: #777676;
      }

      &.italic {
        font-style: italic;
      }

      &.small {
        font-size: getRem(14);
        line-height: getRem(24);
      }

      &.max {
        color: var(--red);
        font-weight: bold;
      }
    }
  }

  .flex-left {
    align-items: flex-start;
  }

  .edit-profile-picture {
    position: relative;

    @include breakpoint(mobile) {
      width: getRem(100);
      margin: 0 auto;
    }

    button {
      width: getRem(40);
      height: getRem(40);
      background: #E4E4E4;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;

      span {
        width: getRem(40);
        height: getRem(40);
        @include font(2, 2);
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      svg {
        width: getRem(20);
        height: auto;
        margin: auto;
      }
    }
  }

  .profile-picture {
    display: block;
    width: getRem(160);
    height: getRem(160);
    margin: 0 auto;
    border-radius: 100%;
    // border: getRem(7) solid var(--yellow);
    overflow: hidden;

    @include breakpoint(mobile) {
      width: getRem(80);
      height: getRem(80);
    }

    img {
      width: 100%;
      height: 100%;
      // width: getRem(135);
      // height: getRem(135);
      display: block;
      // max-height: unset !important;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    // .user-picture {
    //     // border-radius: 100%;
    // }

  }

  h1 {
    @include font(40, 48);
    margin: 0 0 getRem(5);

    @include breakpoint(mobile) {
      text-align: center;

      @include font(28, 36);
    }
  }

  .required-disclaimer {
    color: var(--red);
    margin: 0 0 getRem(8) auto;
    font-size: getRem(14);
    line-height: getRem(24);
  }

  label span.red {
    color: var(--red);
    display: inline;
    margin-left: getRem(2);
  }

  .field-container {
    width: 100%;
    margin-bottom: getRem(16);

    input {
      margin-bottom: 0 !important;
    }

    .error-message {
      color: var(--red);
      display: none;
      margin: 0;
      font-size: getRem(14);
      line-height: getRem(24);
    }

    &.error {

      input,
      select {
        border: 2px solid var(--red) !important;
        box-shadow: 0 0 0 1px var(--red) !important;
      }

      .error-message {
        display: block;
      }
    }
  }

  .edit {
    text-align: center;
    @include font(14, 24);
    font-weight: bold;
    padding-bottom: getRem(25);

    a.editLink,
    button.editLink {
      appearance: none;
      background-color: transparent;
      border: none;
      font-weight: bold;
      color: var(--red);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .gotoSettings {
    @include font(14, 24);
    font-weight: bold;
    text-align: center;
    padding: getRem(20) 0 getRem(40);

    a {
      color: var(--grey);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .form-section {
    .section-inner {
      border-bottom: 1px solid rgba(#2D2D2D, .2);
      padding: getRem(24) 0;
    }

    &:last-child {
      .section-inner {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    h3 {
      color: #292929;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      letter-spacing: 1.75px;
      margin: 0;
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      margin: getRem(10) 0 0;

      &.italic {
        font-style: italic;
      }

      &.light {
        color: #777676;
      }

      &.error-message {
        color: var(--red);
        font-style: italic;
        margin: 0;

        &+.suggestions {
          margin-top: getRem(4);
        }
      }
    }

    .error-message {
      color: var(--red);
      font-style: italic;
      margin: 0;

      &+.suggestions {
        margin-top: getRem(4);
      }
    }

    .italic {
      font-style: italic;
    }
  }

  .multi-input-container {
    min-height: getRem(50);
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    &.grey {
      background: #E4E4E4;
      border: 1px solid #CFCFCF;
      padding: getRem(8) getRem(8) 0;

      @include breakpoint(mobile) {
        padding: getRem(8);
      }
    }

    .background {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    input[type="text"] {
      width: 100%;
      // max-width: getRem(180);
      background: #E4E4E4;
      border: none !important;
      //font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: getRem(24) !important;
      margin-right: getRem(8);
      padding: getRem(8) !important;
      position: relative;
      z-index: 1;
    }

    .option {
      background: #E4E4E4;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: getRem(8);
      margin-right: getRem(8);
      padding: 4px 8px 4px 12px;
      position: relative;
      z-index: 1;

      &:last-child {
        margin-left: 0;
      }

      &:focus,
      &:hover {
        outline: 1px solid black;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;

        button {
          cursor: default;

          &:focus,
          &:hover {
            outline: none;
          }
        }
      }

      &.stamp-option {
        @include breakpoint(mobile) {
          width: 100%;
        }

        svg {
          @include breakpoint(mobile) {
            margin-left: auto;
          }
        }
      }

      .image-container {
        width: getRem(36);
        height: getRem(36);
        background: var(--red);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: getRem(10);
        flex-shrink: 0;

        img {
          max-width: getRem(28);
          max-height: getRem(28);
          margin: auto;
        }
      }

      .ga-overlay {
        width: 100%;
        height: 100%;
        background: transparent;
        display: inline-block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      span {
        text-align: left;
      }

      svg {
        margin-left: getRem(10);
        flex-shrink: 0;
      }
    }

    &+.add-container {
      margin-top: 0;
      padding-top: 0;
    }

    &.add-container {
      .option {
        background-color: #E4E4E4;

        svg g {
          fill: black;
        }
      }
    }

    .suggestions {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      .suggestion-option {
        background: #FFFFFF;
        border-radius: getRem(4);
        display: inline-flex;
        align-items: center;
        margin-bottom: getRem(8);
        margin-right: getRem(8);
        position: relative;
        z-index: 1;

        .ga-overlay {
          width: 100%;
          height: 100%;
          background: transparent;
          display: inline-block;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        button {
          background: transparent;
          border: none;
          border-radius: getRem(4);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-size: getRem(16);
          line-height: getRem(24);
          padding: 4px 8px 4px 12px;
          position: relative;

          &:focus,
          &:hover {
            outline: 1px solid black;
          }

          svg {
            margin-left: getRem(10);
            position: relative;
            z-index: -1;
          }
        }
      }
    }

    .no-suggestions {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 4px 8px 4px 12px;
      margin-bottom: getRem(8);
    }
  }

  button.btn[type='submit'] {
    width: 100%;
  }

  .bottom-section {
    max-width: getRem(676);
    border-top: 1px solid rgba(45, 45, 45, 0.2);
    margin: 0 auto;
    padding-top: getRem(24);
    text-align: center;

    @include breakpoint(mobile) {
      padding-bottom: getRem(24);
    }

    p {
      margin: 0;
    }

    .disclaimer {
      font-weight: 400;
      font-size: getRem(16);
      line-height: getRem(26);
      color: #292929;
      padding: 0 getRem(36) getRem(24);
    }

    .reset-profile {
      font-weight: 700;
      font-size: getRem(14);
      line-height: getRem(14);
      color: #111111;
      padding-top: getRem(24);
      border-top: 1px solid rgba(45, 45, 45, 0.2);

      .rest-button {
        background: transparent;
        border: none;
        color: var(--red);
        cursor: pointer;
        font-weight: bold;
        padding: 0;
      }
    }
  }

  .italic {
    font-style: italic;
  }

  .dialog-layer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    &-inner {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
    }

    .background {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .dialog-box {
      background: white;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.10);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: auto;
      padding: getRem(24) getRem(32);
      position: relative;
      z-index: 1;

      h2 {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 0;
        text-align: center;
      }

      p {
        margin: 16px 0 0;
        text-align: center;
      }

      button.btn {
        width: 100%;
        margin: 32px auto 0;
      }

      form {
        display: flex;
        flex-wrap: wrap;

        div {
          width: 100%;
        }

        input {
          height: getRem(48);
          background: #F7F7F7;
          border: 1px solid #979797;
          border-radius: 2px;
          font-size: 14px;
          color: #292929;
          //letter-spacing: 1.75px;
          line-height: 18px;
          max-width: getRem(468);
          margin-bottom: 0;
        }

        button {
          cursor: pointer;
          margin: getRem(36) auto 0;
        }
      }

      .cancel {
        background: none;
        border: none;
        font-size: 14px;
        color: var(--red);
        cursor: pointer;
        letter-spacing: 0;
        line-height: 24px;
        margin: getRem(8) auto 0;
      }
    }
  }

  &.public-profile-page {
    .public-profile-container {
      background: none;
      box-shadow: none;
      padding: 0;
    }

    .profile-top {
      text-align: center;

      h1 {
        margin: getRem(8) 0 0;
      }

      p {
        margin: getRem(4) 0 0;
      }
    }

    .user-info-container {
      width: 80%;
      max-width: getRem(600);
      background: var(--light-grey);
      box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.2);
      margin: 0 auto getRem(64);
      padding: 1.875rem 2.5rem;

      @include breakpoint(mobile) {
        width: 100%;
        max-width: none;
      }
    }
  }
}