.swiper-button-prev {
    &:after {
        content: '' !important;
        width: getRem(40) !important;
        height: getRem(40) !important;
        background: url('/../../images/ui-swiper-prev.svg') 50% no-repeat;
    }
}

.swiper-button-next {
    &:after {
        content: '' !important;
        width: getRem(40) !important;
        height: getRem(40) !important;
        background: url('/../../images/ui-swiper-next.svg') 50% no-repeat;
    }
}

.swiper-slide {
    figure {
        img {
            object-fit: contain !important;
        }
    }

    figcaption {
        margin-top: getRem(16);
    }
}