.errorPage {
    text-align: center;
    margin-bottom: 75px;
    background-image: url("/images/404-banner.jpg");
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (max-width: 600px) {
        margin-bottom: 60px;
        background-size: auto 30vh;
    }

    h1 {
        font-size: getRem(200);
        line-height: getRem(200);
        margin: 100px 0 130px 0;

        @media screen and (max-width: 1100px) {
            margin: 60px 0 90px 0;
        }

        @media screen and (max-width: 900px) {
            font-size: getRem(100);
            line-height: getRem(100);
            margin: 60px 0 90px 0;
        }

        @media screen and (max-width: 600px) {
            margin: 30px 0 60px 0;
        }
    }

    p {
        max-width: 600px;
        font-size: getRem(40);
        line-height: getRem(44);
        font-weight: bold;
        margin: 0 auto 30px auto;

        @media screen and (max-width: 900px) {
            font-size: getRem(36);
            line-height: getRem(40);
        }

        @media screen and (max-width: 600px) {
            font-size: getRem(24);
            line-height: getRem(30);
        }
    }
}
