@import "../global/mixins";
@import "buttons";

.mainContent {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.card {
    background: var(--white);
    box-shadow: var(--shadow);
    border-radius: 0 0 6px 6px;
}
