body,
html {
  padding: 0;
  margin: 0;
  font-family: Speedee, Droid Sans, Helvetica Neue, sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  overflow-x: clip;

  // TODO: See if I should remove this
  //overflow-x: hidden;
  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  &.no-legal-notice {
    height: auto !important;
    overflow: auto !important;
  }
}

html {
  scroll-behavior: smooth;
}

::selection {
  background-color: var(--selection-bg);
  color: var(--selection-color);
}

.sr-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0); 
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap; 
	width: 1px;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
li a,
p a,
ul a {
  font-weight: bold;
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: var(--blue-dark);
  }
}

*,
button {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline-offset: 0;
    outline-color: black;
    outline-style: auto;
    outline-width: 1px;
  }
}

.wrapper {
  width: 100%;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--padding);
  padding-right: var(--padding);

  &.wrapper-small,
  .wrapper {
    max-width: var(--max-width-small);
    padding: 0;
  }

  &.wrapper-small {
    padding: 0 var(--padding);
  }
}

*::placeholder {
  color: inherit;
  opacity: 0.62;
}

.h1,
h1 {
  font-weight: bold;
  font-size: getRem(72);
  @include lineHeightRatio(72, 80);

  &.large {
    font-size: getRem(88);
    @include lineHeightRatio(88, 96);
  }

  &.small {
    font-size: getRem(64);
    @include lineHeightRatio(64, 72);
  }

  @include breakpoint(tablet) {
    font-size: getRem(50);
    @include lineHeightRatio(50, 48);

    &.large {
      font-size: getRem(64);
      @include lineHeightRatio(64, 64);
    }

    &.small {
      font-size: getRem(40);
      @include lineHeightRatio(40, 45);
    }
  }

  @include breakpoint(mobile) {
    font-size: getRem(40);
    @include lineHeightRatio(40, 52);

    &.large {
      font-size: getRem(44);
      @include lineHeightRatio(44, 46);
    }

    &.small {
      font-size: getRem(36);
      @include lineHeightRatio(36, 45);
    }
  }
}

.h2,
h2 {
  font-weight: bold;
  font-size: getRem(40);
  @include lineHeightRatio(40, 48);

  &.large {
    font-size: getRem(48);
    @include lineHeightRatio(48, 56);
  }

  &.small {
    font-size: getRem(32);
    @include lineHeightRatio(32, 40);
  }

  @include breakpoint(tablet) {
    font-size: getRem(30);
    @include lineHeightRatio(30, 44);

    &.large {
      font-size: getRem(32);
      @include lineHeightRatio(32, 45);
    }

    &.small {
      font-size: getRem(28);
      @include lineHeightRatio(28, 36);
    }
  }

  @include breakpoint(mobile) {
    font-size: getRem(32);
    @include lineHeightRatio(32, 45);

    &.large {
      font-size: getRem(30);
      @include lineHeightRatio(30, 44);
    }

    &.small {
      font-size: getRem(28);
      @include lineHeightRatio(28, 36);
    }
  }
}

.h3,
h3 {
  font-weight: bold;
  font-size: getRem(24);
  @include lineHeightRatio(24, 32);

  &.large {
    font-size: getRem(28);
    @include lineHeightRatio(28, 36);
  }

  &.small {
    font-size: getRem(20);
    @include lineHeightRatio(20, 28);
  }

  @include breakpoint(tablet) {
    font-size: getRem(22);
    @include lineHeightRatio(22, 30);

    &.large {
      font-size: getRem(24);
      @include lineHeightRatio(24, 32);
    }

    &.small {
      font-size: getRem(20);
      @include lineHeightRatio(20, 28);
    }
  }

  @include breakpoint(mobile) {
    font-size: getRem(22);
    @include lineHeightRatio(22, 30);

    &.large {
      font-size: getRem(24);
      @include lineHeightRatio(24, 32);
    }

    &.small {
      font-size: getRem(20);
      @include lineHeightRatio(20, 28);
    }
  }
}

h4 {
  font-weight: bold;
  font-size: getRem(21);
  @include lineHeightRatio(21, 30);

  @include breakpoint(mobile-tablet) {
    font-size: getRem(18);
    @include lineHeightRatio(18, 28);
  }
}

ol,
p,
ul {
  font-size: getRem(18);
  @include lineHeightRatio(18, 28);

  &.large {
    font-size: getRem(21);
    @include lineHeightRatio(21, 41);
  }

  // TODO: Clarify with Emma
  // &.small{
  //   font-size: getRem(16);
  //   @include lineHeightRatio(16, 26);
  // }
  &.small {
    font-size: getRem(16);
    @include lineHeightRatio(16, 22);
  }

  @include breakpoint(tablet) {
    font-size: getRem(18);
    @include lineHeightRatio(18, 28);

    &.large {
      font-size: getRem(19);
      @include lineHeightRatio(19, 40);
    }

    &.small {
      font-size: getRem(16);
      @include lineHeightRatio(16, 26);
    }
  }

  @include breakpoint(mobile) {
    font-size: getRem(18);
    @include lineHeightRatio(18, 28);

    &.large {
      font-size: getRem(19);
      @include lineHeightRatio(19, 40);
    }

    &.small {
      font-size: getRem(16);
      @include lineHeightRatio(16, 26);
    }
  }
}

article,
header {
  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.large ol:not(.small),
.large p:not(.small),
.large ul:not(.small) {
  font-size: getRem(21);
  @include lineHeightRatio(21, 41);

  @include breakpoint(tablet) {
    font-size: getRem(19);
    @include lineHeightRatio(19, 40);
  }

  @include breakpoint(mobile) {
    font-size: getRem(19);
    @include lineHeightRatio(19, 40);
  }
}

figcaption {
  letter-spacing: 0;
  font-size: getRem(14);
  @include lineHeightRatio(14, 24);
  text-transform: unset;
  color: var(--text-light);

  @include breakpoint(tablet) {
    @include lineHeightRatio(14, 24);
  }

  @include breakpoint(mobile) {
    font-size: getRem(14);
    @include lineHeightRatio(14, 24);
  }
}

.caption,
form label {
  font-size: getRem(14);
  @include lineHeightRatio(14, 18);
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: getRem(1.75);
  padding-bottom: 0.25em;
  display: block;

  @include breakpoint(mobile) {
    letter-spacing: getRem(1.5);
  }

  &.small {
    letter-spacing: 0;
    font-size: getRem(14);
    @include lineHeightRatio(14, 24);
    text-transform: unset;
    color: var(--text-light);
  }

  @include breakpoint(tablet) {
    font-size: getRem(14);
    @include lineHeightRatio(14, 18);

    &.small {
      font-size: getRem(14);
      @include lineHeightRatio(14, 24);
    }
  }

  @include breakpoint(mobile) {
    font-size: getRem(14);
    @include lineHeightRatio(14, 18);

    &.small {
      font-size: getRem(14);
      @include lineHeightRatio(14, 24);
    }
  }
}

.quote,
blockquote {
  font-weight: bold;
  font-size: getRem(40);
  @include lineHeightRatio(40, 48);

  @include breakpoint(mobile) {
    font-size: getRem(30);
    @include lineHeightRatio(30, 44);
  }
}

a.link.primary {
  font-size: getRem(16);
  @include lineHeightRatio(16, 22);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -0.125em;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--red);
    transform: scale3d(1, 1, 1);
    transition: transform 0.165s md-ease(ease-in-out);
  }

  &:hover {
    &::after {
      transform: scale3d(0.75, 1, 1);
    }
  }
}

a.link.secondary {
  font-size: getRem(14);
  @include lineHeightRatio(14, 20);
  color: var(--red);
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: var(--red-dark);
    text-decoration: underline;
  }
}

@include breakpoint(mobile-tablet) {
  #topnav .wrapper {
    max-width: 100%;
  }
}

div.page,
main.page {
  padding-top: var(--nav-height);
}

.bold,
.strong {
  font-weight: bold;
}

div.pageComponent {
  margin-top: calc(var(--nav-height) + var(--page-margin));
  position: relative;
  min-height: getRem(300);
}

.seedBackground {
  position: absolute;
  top: calc(var(--page-margin) * -1);
  left: 0;
  width: 100%;
  height: getRem(414);
  background-color: var(--yellow);
  z-index: -1;

  @include breakpoint(tablet) {
    height: getRem(355);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

// form stuff
// select
select {
  min-height: getRem(36);
  background-color: var(--light-grey);
  border: 1px solid var(--form-border);
  border-radius: 2px;
  appearance: none;
  padding: 0 getRem(36) 0 getRem(12);
  background-image: var(--ui-select);
  background-position: calc(100% - 0.5rem) calc(50% - 0.125rem);
  background-repeat: no-repeat;
  background-size: auto getRem(12);
  color: var(--black);
}

input[type="text"],
textarea {
  &:focus {
    outline: none;
    // background-color: rgba(#ffbc0d, 0.125);
    border-color: var(--black) !important;
    box-shadow: 0 0 0 1px var(--black);
  }

  // &:focus-visible {
  //     outline: getRem(2) solid pink;
  // }
  // &:focus:not(:focus-visible) {
  //     outline: none !important;
  // }
}

main form:not(.search) {
  label {
    @extend .caption;
    margin-bottom: 0.5em;
  }

  input:not([type="submit"]),
  textarea {
    width: 100%;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    border-radius: getRem(2);
    border: 1px solid var(--form-border);
  }
}

input[type="radio"],
input[type="checkbox"] {
  width: getRem(25) !important;
  height: getRem(25) !important;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: var(--ui-radio-unchecked);
  appearance: none;
  border: none !important;
  display: block;
  margin-right: 1rem;

  &:checked {
    background-image: var(--ui-radio-checked);
  }

  &:focus-visible {
    filter: drop-shadow(0px 0px 4px var(--blue));
    outline: none;
  }
}

input[type="checkbox"],
input.checkbox {
  background-image: var(--ui-checkbox-unchecked);

  &:checked {
    background-image: var(--ui-checkbox-checked);
  }
}

.pagination {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;

    @include breakpoint(mobile) {
      justify-content: center;
    }

    li {
      width: 2rem;
      height: 3rem;
      // background-color: lightblue;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.break {
        display: block;
        width: auto;
        // background-color: lightcoral;
        display: flex;
        flex-wrap: nowrap;
        letter-spacing: 0.5em;

        @include breakpoint(mobile-tablet) {
          letter-spacing: 0;
          margin-right: 0.25em;
        }

        a {
          padding: 0;
          cursor: unset;
        }
      }

      &.selected::after {
        content: "";
        width: getRem(8);
        height: getRem(8);
        background-color: var(--red);
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: calc(50% - #{getRem(4)});
      }
    }

    a {
      padding: 0.75rem;
      color: var(--black);
      cursor: pointer;
      user-select: none;
    }

    .selected a {
      font-weight: normal;
    }
  }
}

p.right {
  text-align: right;
}

p.left {
  text-align: left;
}

p.center {
  text-align: center;
}

.__react_component_tooltip {
  @include font(14, 14);
  color: var(--grey);
}

.__react_component_tooltip:before {
  display: none;
}

// Advanced form components
.basic-multi-select {
  margin-bottom: 1rem;
}

.basic-multi-select .select__value-container {
  .select__input {}

  .select__input input {
    // width: 100% !important;
    height: 100%;
    line-height: 100%;
    margin: 0;
    // background-color: lightseagreen;
    border-color: transparent;
    outline: none;
    padding: 0 0.25em;
    outline: none;
    border-color: transparent;
    appearance: none;
    outline-color: transparent;
    box-shadow: none;
  }
}

.react-datepicker__tab-loop {
  // background-color: blue;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

// .react-datepicker-wrapper {
//     position: relative;
//     z-index: 20;
//     .react-datepicker__month-container {
//         z-index: 20;
//     }
// }

.react-datepicker__input-container input {
  background-image: var(--ui-calendar) !important;
  background-position: 0.5rem center;
  background-repeat: no-repeat;
  background-size: 18px;
  padding-left: 2.5rem !important;
  background-color: var(--light-grey);
  border-color: var(--form-border);
  border-radius: getRem(2);
}

// React Select
.select__control {
  // background-color: var(--light-grey);
  border-color: var(--form-border);
}

.select__indicators div {
  color: var(--black);
}

.select__menu {
  // background-color: green !important;
  // display: none;
}

.select__menu .select__option {
  &.select__option--is-selected {
    background-color: var(--black);
    color: var(--yellow);
  }

  &.select__option--is-focused,
  &:active,
  &:focus,
  &:hover {
    background-color: var(--yellow);
    color: var(--black);
  }
}

//Loading Spinner
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--yellow);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//Magic Bell
.notfications-link {
  p {
    font-weight: 600 !important;
  }

  article,
  div {
    font-weight: normal !important;
  }

  svg circle {
    &:first-child:last-child {
      fill: var(--red);
    }
  }

  &>div {
    &>div:last-child {
      opacity: 1;

      img {
        display: none !important;
      }
    }
  }
}

.css-ujd0de {
  svg path {
    fill: black !important;
  }
}

.topnav.dark {
  .css-ujd0de {
    svg path {
      fill: white !important;
    }
  }

  .notfications-link.menu-open {
    .css-ujd0de {
      svg path {
        fill: black !important;
      }
    }
  }
}

.marker-yellow {
  background-color: var(--yellow) !important;
}

// Nav global
a[data-url="2022-feel-good-marketing-awards"] {
  border-top: 1px solid rgba(45, 45, 45, 0.2);
  padding-top: getRem(24);
}

// tables
.table {
	max-width: 100%;
	margin: 0;
	overflow: auto;

	ul {
		padding-left: 1rem;
	}
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}