.faq {
  width: calc(100% - 25px);
  max-width: 700px;
  margin: 150px auto 100px auto;

  @include breakpoint(tablet) {
    margin: 100px auto 50px auto;
  }

  @include breakpoint(mobile) {
    margin: 100px auto 50px auto;
  }

  h1 {
    font-size: getRem(42);
  }

  h2 {
    color: var(--red);
    font-size: getRem(28);
  }

  h3 {
    font-size: getRem(20);
  }

  p,
  li {
    font-size: getRem(16);
  }

  ul {
    margin: 0 0 0 20px;
    padding: 0;
  }

  .contact-info {
    margin-bottom: getRem(16);

    p {
      margin-top: 0;
      margin-bottom: getRem(8);

      &:first-child {
        font-weight: bold;
        margin-bottom: getRem(4);
      }
    }

    a {
      font-weight: bold;
      color: var(--blue);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: var(--blue-dark);
      }
    }
  }
}
