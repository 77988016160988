.newestPage {
    .result-cards {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: getRem(40);
        align-items: flex-start;
        margin-bottom: getRem(95);
        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include breakpoint(mobile) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .stamps {
        ul {
            margin: 0 0 0 getRem(10) !important;
            padding: 0 !important;
        }

        li {
            margin: 0 !important;
            padding: 0 !important;

            > div {
                margin: 0 0 0 getRem(10) !important;
            }
        }

        .stamp-label {
            display: none !important;
        }

        .shamelessly-stolen,
        .creative-effectiveness {
            display: none;
        }
    }

    .show-more {
        display: flex;
        justify-content: center;

        .btn {
            margin: 0 0 getRem(50) 0 !important;

            &--disabled {
                display: none;
            }
        }
    }
}
