.breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0;

    a,
    li {
        color: #535353;
        @include font(14, 21);
        letter-spacing: getRem(3.5);
    }

    a {
        font-weight: 400;
        text-transform: uppercase;
    }

    li {
        display: inline-block;
        font-weight: 600;
        vertical-align: middle;

        &:not(:first-child) {
            padding-left: getRem(4);
            position: relative;

            &:before {
                content: '/';
                display: inline;
                vertical-align: middle;
                padding-right: getRem(4);
            }
        }
    }
}