.support-btn-container {
  position: fixed;
  bottom: getRem(30);
  //right: getRem(20);
  right: getRem(88);
  z-index: 1000;
}

.btn.support-btn {
  min-width: getRem(60);
  height: getRem(60);
  cursor: pointer;
  margin-bottom: 0;
  padding: getRem(12);
  z-index: 999;

  .btn-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: width 0.33s md-ease(ease-in-out);
  }

  span, svg {
    display: inline-block;
    vertical-align: middle;
    justify-content: flex-end;
  }

  span {
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: all .33s md-ease(ease-in-out);
  }

  svg {
    width: getRem(33);
    transition: all .33s md-ease(ease-in-out);

    path, circle {
      transition: fill 0.33s md-ease(ease-in-out);
    }
  }

  &:hover {
    span {
      max-width: getRem(1000);

      & + svg {
        margin-left: getRem(16);
      }
    }

    svg path,
    svg circle {
      fill: white;
    }
  }
}

.support-form-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .background {
    width: 100%;
    height: 100%;
    background: rgba(black, .5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .container-inner {
    width: 80%;
    height: 80%;
    background: white;
    border-radius: getRem(5);
    position: relative;
    z-index: 1;

    .my-form {
      width: 100% !important;
      height: 100% !important;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.tf-v1-popover-button {
  --radius: #{getRem(3)};
  width: getRem(60) !important;
  height: getRem(60) !important;
  background-color: var(--yellow) !important;
  border-radius: var(--radius) !important;
  overflow: hidden;
  bottom: getRem(30) !important;
  right: getRem(20) !important;

  @include breakpoint(mobile) {
    bottom: getRem(40) !important;
  }

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: rebeccapurple;
    border-radius: var(--radius);
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--red);
    transform: translate3d(-101%, 0, 0);
    transition: transform 0.33s md-ease(ease-in-out);
  }

  &:hover {
    color: var(--white);
    text-decoration: none;
    &::after {
      // transform: translate3d(-2em, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.tf-v1-popover-tooltip {
  @include breakpoint(mobile) {
    bottom: getRem(40) !important;
    right: getRem(88) !important;
  }
}
