.accordion {
    .accordion__item {
        border-top: 1px solid var(--form-border);
        border-bottom: 1px solid transparent;
        &:last-child {
            border-bottom-color: var(--form-border);
        }
    }
    .accordion__heading {
        @extend h3;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .accordion__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;
        cursor: pointer;
        // background-image: var(--ui-plus);
        background-repeat: no-repeat;
        background-size: getRem(22) auto;
        background-position: right center;
        &[aria-expanded="false"] {
            background-image: var(--ui-plus);
        }
        &[aria-expanded="true"] {
            background-image: var(--ui-minus);
        }
    }
}
