@import "../global/mixins";
@import "../global/animations";

article.article-detail {
	// Vars for this section only
	// --image-clip-size: #{getRem(10000)};
	--image-clip-size: 900vw;
	--content-max-width: #{getRem(778)};
	--header-height: #{getRem(670)};
	position: relative;
	z-index: 1;

	@include breakpoint(tablet) {
		--header-height: #{getRem(500)};
	}

	@include breakpoint(mobile-tablet) {
		--max-width-small: #{getRem(689)};
	}

	@include breakpoint(mobile) {
		--header-height: #{getRem(560)};
	}

	.page-header {
		display: block;
		position: relative;
		// background-color: var(--light-grey);
		min-height: var(--header-height);
		// height: var(--header-height);
		padding: 0;
		margin: 0;
		top: 0;

		.banner {
			position: relative;
		}

		.image-clipped {
			position: absolute;
			width: 100%;
			height: calc(100% - var(--nav-height));
			top: 0;
			left: 0;
			padding: 0;
			margin: 0;
			margin-top: calc(var(--nav-height));
			z-index: 0;
			clip-path: circle(var(--image-clip-size) at 100% calc(-1 * var(--image-clip-size) + 100%));

			&::after {
				content: "";
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				background-color: rgba(#232323, 0.3);
				top: 0;
				left: 0;
			}
		}

		// .image-clipped img {
		//     // width: 100%;
		//     // height: 100%;
		// }
		.wrapper {
			@include breakpoint(tablet-mobile) {
				max-width: var(--max-width-small);
			}

			&:nth-child(2) {
				position: relative;
				z-index: 102;
			}
		}

		.wrapper .title-block {
			position: relative;
			z-index: 1;
			color: var(--white);
			padding-top: getRem(156);
			max-width: var(--content-max-width);
			margin-left: 0;
			margin-bottom: getRem(180);

			@include breakpoint(mobile-tablet) {
				padding-top: getRem(80);
			}

			@include breakpoint(mobile) {
				margin-bottom: getRem(40);
			}
		}

		.subtitle {
			@include font(20, 30);
			margin-block: getRem(-32) getRem(48);
		}

		.meta {
			color: var(--white);
			// font-weight: bold;
			text-transform: uppercase;
			font-size: getRem(14);
			@include lineHeightRatio(14, 18);
			display: flex;
			flex-wrap: wrap;

			@include breakpoint(mobile) {
				display: flex;
				flex-wrap: wrap;
			}

			.views {
				svg {

					path,
					circle {
						stroke: var(--white);
					}
				}
			}

			.category,
			.country,
			.date {
				@include font(14, 24);
				font-weight: normal;
				margin: 0;
				text-transform: none;

				img,
				svg {
					margin-right: getRem(4);
				}

				svg path {
					fill: #FFFFFF;
				}
			}

			.date {
				flex-shrink: 0;
			}

			.country,
			.category {
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				margin-bottom: auto;
			}

			// .category {
			//   margin-left: getRem(8);
			// }

			.categoryContainer,
			.countryContainer {
				max-width: calc(100% - 50px);
				display: inline-flex;
				position: relative;

				&:hover {
					.metaHover {
						display: inline-block;
					}
				}

				.metaHover {
					width: calc(100% + 32px);
					max-width: getRem(200);
					background: #FFFFFF;
					box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
					color: black;
					display: none;
					padding: getRem(16);
					position: absolute;
					top: 0;
					left: getRem(8);
					z-index: 1000;

					.hoverLabel {
						color: #292929;
						display: flex;
						@include font(14, 18);
						margin: 0;

						span {
							@include font(14, 18);
							text-transform: uppercase;
						}

						img,
						svg {
							margin-right: getRem(4);
						}
					}

					ul {
						list-style: none;
						margin: getRem(10) 0 0;
						padding: 0;

						li {
							color: #006BAE;
							@include font(14, 18);
							// white-space: nowrap;
						}
					}

					svg path {
						fill: #2D2D2D;
					}
				}
			}

			.topMeta {
				display: flex;
				flex-wrap: nowrap;
				column-gap: getRem(8);
			}

			.bottomMeta {
				width: 100%;
				display: flex;
				margin-top: getRem(32);
				grid-column-gap: getRem(16);

				.thumbsUpItem,
				.bookmarkItem {
					background: rgba(0, 0, 0, 0.5);
					border-radius: getRem(2);

					span {
						width: auto;
						height: auto;
						transform: none;
						position: static;
					}

					svg {
						width: getRem(18);
						height: auto;
						margin-right: getRem(8);

						path {
							stroke: var(--white);
						}
					}

					p {
						color: var(--white);
						@include font(16, 18);
						font-weight: 700;
						margin: 0;
					}
				}

				.thumbsUpItem {
					margin-bottom: auto;
					padding: 0;

					button {
						display: flex;
						align-items: center;
						padding: getRem(8);
						position: relative;

						.reaction-overlay {
							width: 100% !important;
							height: 100% !important;
							@include font(2, 2);
							opacity: 0;
							position: absolute !important;
							top: 0;
							left: 0;
							z-index: 101;
						}
					}

					svg {
						path {
							&:first-child {
								fill: var(--white);
							}

							&:last-child {
								fill: transparent;
							}
						}
					}
				}

				.bookmarkItem {
					display: flex;
					align-items: center;
					border-radius: getRem(2);
					margin-bottom: auto;

					button {
						display: flex;
						align-items: center;
						margin: 0;
						// padding-left: 0;

						svg {
							path {
								fill: transparent;
							}
						}

						&.is-bookmarked {
							svg {
								path {
									fill: var(--white);
								}
							}
						}
					}
				}

				.follow-container {
					button {
						svg {
							margin-right: getRem(8);
						}

						span {
							margin: 0;
						}
					}
				}

				.share-button-container {
					button {
						margin: 0;

						&:before,
						&:after {
							display: none;
						}

						svg {
							margin-right: getRem(8);

							path {
								fill: var(--white);
								stroke: var(--white);
							}
						}

						span {
							@include font(18, 18);
						}
					}

					ul.share-list {
						span {
							color: var(--black);
							text-transform: none;
						}
					}
				}
			}

			.follow-container,
			.share-button-container {
				position: relative;

				// @include breakpoint(mobile) {
				//   width: 100%;
				// }

				button.followButton {
					min-height: getRem(38);
					background: rgba(0, 0, 0, 0.5);
					border: 1px solid rgba(255, 255, 255, 0.2);
					border-radius: getRem(2);
					color: #FFFFFF;
					display: flex;
					align-items: center;
					padding: getRem(8);
					font-weight: 700;
					font-size: 18px;
					line-height: 18px;

					span {
						position: static;
						transform: none;
						margin-right: getRem(8);
					}

					svg {
						width: getRem(20);
						overflow: visible;
						z-index: 0;

						g {
							fill: #FFFFFF;
						}
					}
				}

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li {
						border-bottom: 1px solid rgba(45, 45, 45, 0.2);
						margin: 0;
						padding: getRem(16) getRem(24);
						position: relative;

						&:last-child {
							border-bottom: none;
						}

						&.disabled {
							opacity: 0.3;

							* {
								pointer-events: none;
							}
						}

						label {
							width: 100%;
							height: 100%;
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
						}

						.content-container {
							display: flex;
							align-items: center;
							display: grid;
							grid-template-columns: 5fr 3fr 1fr;
						}

						p {
							color: #292929;
							font-size: 21px;
							line-height: 26px;
							margin: 0 1rem 0 0;
							text-transform: none;

							&.type {
								font-size: 14px;
								line-height: 24px;
								color: #777676;
								// margin: 0 getRem(60) 0 getRem(16);
							}
						}

						input {
							margin-left: auto;
							margin-right: 0;
							flex-shrink: 0;
						}
					}
				}

				&.open {
					button.followButton {
						background-color: #DB0007;
					}

					.follow-options-container {
						display: inline-block;
					}
				}
			}

			.share-button-container {
				ul {
					padding: getRem(8) getRem(16);
				}
			}
		}

		h1 {
			margin-top: 0.25em;
		}

		// .raction svg {
		// }
		.reaction p {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			line-height: 1em;
			margin-bottom: 0;

			.reaction-image {
				margin-right: getRem(5.5);
			}
		}

		.wrapper .takeaway-block {
			position: relative;
			z-index: 1;
			color: var(--white);
			padding-top: 0;
			max-width: var(--content-max-width);
			margin-top: getRem(-150);
			margin-left: 0;

			@include breakpoint(mobile) {
				margin-top: getRem(-40);
			}
		}

		#takeaway-block {
			position: relative;
			z-index: 101;

			a {
				color: var(--red);

				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.takeaway {
			--selection-bg: var(--red);
			--selection-color: var(--white);
			background-color: var(--yellow);
			width: 100%;
			height: 100%;
			padding: getRem(34) getRem(44);
			border-top-left-radius: getRem(8);
			border-top-right-radius: getRem(8);
			color: var(--black);
			margin-top: getRem(20);

			// p,
			// ol,
			// ul {
			//     // TODO: This is a font override, need to be sure I adjust for each viewport
			//     font-size: getRem(21);
			//     @include lineHeightRatio(21, 41);
			//     // font-weight: lighter;
			// }
			@include breakpoint(mobile) {
				padding: getRem(16);
			}
		}

		.takeway-text {
			&+.files {
				margin-top: 2rem;
			}
		}

		.files {
			background-color: var(--white);
			padding: getRem(20) getRem(33);
			border-radius: getRem(3);

			button {
				appearance: none;
				margin: 0;
				padding: 0;
				background-color: transparent;
				border: none;
				cursor: pointer;
			}

			li {
				margin: 0;
			}

			button svg {
				pointer-events: none;
			}
		}

		.video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.page-header-alt {
		@extend .page-header;

		.wrapper {
			position: relative;
		}

		.banner {
			position: relative;

			&::before {
				content: "";
				top: 0;
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--light-grey);
			}

			.category,
			.country,
			.date {
				svg path {
					fill: var(--black);
				}
			}
		}

		.image-clipped {
			clip-path: unset;
			width: 50%;
			left: unset;
			right: 0;

			@include breakpoint(mobile) {
				position: relative;
				display: block;
				width: 100%;
				height: getRem(226);
			}

			&::after {
				display: none;
			}
		}

		@include breakpoint(mobile) {
			.wrapper .title-block {
				padding-top: getRem(80);
			}
		}

		.wrapper .title-block .title {
			color: var(--black);
			max-width: getRem(550);

			@include breakpoint(desktop) {}

			@include breakpoint(tablet) {
				max-width: getRem(330);
			}

			// @include breakpoint(mobile) {
			//     // height: getRem(500);
			//     background-color: var(--light-grey);
			// }
		}

		.meta {
			color: var(--black);

			.views {
				svg {

					circle,
					path {
						stroke: var(--yellow);
					}
				}
			}
		}
	}

	.wrapper {
		// max-width: 80vw;
		// max-width: getRem(1280);

		// @media (max-width: 1024px) {
		//   max-width: 90vw;
		// }

		// @include breakpoint(tablet-mobile) {
		//   max-width: var(--max-width-small);
		// }

		.date {
			font-weight: bold;
			font-size: getRem(14);
			@include lineHeightRatio(14, 28);
		}

		blockquote,
		ol,
		p,
		ul {
			max-width: var(--content-max-width);
		}

		.marker-yellow {
			background-color: var(--yellow);
			padding: 4px 0;
		}
	}

	hr {
		height: 0;
		padding: 0;
		margin: getRem(56) 0;
		border: 1px solid rgba(#2d2d2d, 0.2);
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-top: 1.5em;
	}

	h2 {
		margin-bottom: 0;

		&+p {
			margin-top: 0;
		}
	}

	h1 a,
	h2 a,
	h3 a,
	h4 a,
	h5 a,
	h6 a,
	li a,
	p a,
	ul a {
		font-weight: bold;
		color: var(--blue);
		text-decoration: none;

		// fix for bad text copy/paste
		span {
			color: var(--blue) !important;
			background-color: transparent !important;
		}

		&:hover {
			color: var(---blue-dark);
			text-decoration: underline;

			span {
				color: var(--blue-dark) !important;
			}
		}
	}

	del {
		opacity: 0.42;
	}

	&.comment-mode {
		cursor: crosshair !important;
		display: block;
		user-select: none !important;

		// pointer-events: none !important;
		&>* {
			user-select: none !important;
			pointer-events: none !important;
		}
	}

	.ck-gallery {
		.swiper-slide {
			figure img {
				width: auto !important;
				min-width: none !important;
				height: auto !important;
				min-height: none !important;
				object-fit: contain !important;
			}
		}
	}

	.up-next-article {}
}

.article {
	margin-bottom: getRem(80);

	.ck-sidebar,
	.secondary-sidebar-text,
	.sidebar-image-caption {
		// margin-left: auto;
		// margin-right: auto;
		max-width: var(--content-max-width);
		margin: getRem(80) 0 getRem(40);

		@include breakpoint(desktop) {
			// float: right;
			// margin: unset -0.5rem unset unset;
			// max-width: 300px;
			// padding-left: 2rem;
		}

		@media screen and (min-width: 1300px) {
			// margin-right: -4rem;
			// padding-left: unset;
		}

		@include breakpoint(mobile-tablet) {
			// margin-top: getRem(80);
			// margin-bottom: getRem(80);
		}

		@include breakpoint(tablet) {
			// margin-left: getRem(67);
		}

		img {
			max-width: 100%;
		}

		&.rounded {
			img {
				border-radius: 100%;
			}
		}

		figure.image {
			max-width: 100%;
			padding: 0;
			margin: 0;

			img {
				max-width: 100%;
			}
		}
	}

	//.image-style-align-right {
	//    margin-left: 2rem;
	//
	//    @include breakpoint(tablet) {
	//        margin-left: 0;
	//    }
	//}
	//
	//.image-style-align-left {
	//    margin-right: 2rem;
	//
	//    @include breakpoint(tablet) {
	//        margin-right: 0;
	//    }
	//}

	.wrapper {
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.articleContent {
		width: calc(100% - 312px);
		padding-right: getRem(74);

		@media (max-width: 768px) {
			width: 100%;
			padding-right: 0;
		}
	}
}

article .image,
article .image-style-full {
	width: 100%;
	max-width: var(--content-max-width);
	margin: getRem(40) 0;
}

article .image-style-align-left {
	width: 100%;
	max-width: var(--content-max-width);
	margin: auto;

	@include breakpoint(desktop) {
		float: left;
		width: 50%;
		max-width: calc(var(--content-max-width) / 2);
		margin: getRem(40) getRem(40) getRem(40) 0;
	}

	img {
		max-width: 100%;
	}
}

article figure.image-style-align-right {
	width: 100%;
	max-width: calc(var(--content-max-width) / 2);
	margin: auto;

	@include breakpoint(desktop) {
		float: right;
		width: 50%;
		margin: getRem(40) getRem(60) getRem(40) getRem(40);
	}

	img {
		max-width: 100%;
	}
}

article img.image-style-align-right {
	width: 100%;
	max-width: calc(var(--content-max-width) / 2);
	margin: auto;

	@include breakpoint(desktop) {
		float: right;
		width: 50%;
		margin: getRem(40) 0 getRem(40) getRem(40);
	}

	img {
		max-width: 100%;
	}
}

article .ck-sidebar-content {
	h2 {
		&:first-of-type {
			margin-top: 0;
		}
	}

	.image-style-round {
		border-radius: 50%;
	}
}

article .ck-sidebar,
article .secondary-sidebar-text {
	display: block;
	// float: right;
	// max-width: getRem(360);
	position: relative;

	@include breakpoint(desktop) {
		// margin-top: -10rem;
	}

	* {
		color: var(--text-light2);
	}

	&::before {
		content: "";
		position: absolute;
		top: getRem(-25);
		width: getRem(83);
		height: getRem(8);
		background-color: var(--yellow);
		pointer-events: none;
		border-bottom-left-radius: getRem(4);
		border-top-right-radius: getRem(4);
	}

	.video-block {
		margin: 1rem 0;
	}

	blockquote {
		margin: 0;
		padding: 0;

		&::after,
		&::before {
			content: none;
			display: none;
		}
	}
}

.sidebar-image-caption {
	// float: right;
	max-width: getRem(360);
	width: 100%;
	height: auto;
	min-height: 200px;
	position: relative;
	// background-color: blue;
	padding: 0;
	margin: 0;

	figcaption {
		font-size: getRem(21);
		@include lineHeightRatio(21, 41);
		margin-top: 2em;
	}

	@include breakpoint(desktop) {
		figure {
			margin: 0;
		}
	}
}

article .block-main-img-fullwidth {
	width: 100vw;
	margin-left: calc(((100vw - 100%) / 2) * -1);
}

article blockquote {
	--content-max-width: #{getRem(600)};
	display: block;
	position: relative;
	padding-top: getRem(46);
	margin-top: getRem(80);
	margin-bottom: getRem(80);

	&::before {
		display: block;
		position: absolute;
		content: "";
		width: getRem(160);
		height: getRem(16);
		background-color: var(--yellow);
		border-top-right-radius: getRem(8);
		border-bottom-left-radius: getRem(8);
		top: 0;
		left: 0;
		pointer-events: none;
	}
}

article .image-textwidth {
	max-width: var(--content-max-width);
	position: relative;
	display: block;
	margin: getRem(80) 0;

	figure {
		max-width: 100%;

		img {
			max-width: 100%;
		}
	}
}

article .ck-callout,
article .grey-callout {
	clear: both; // going old school
	margin: getRem(80) 0;
	// width: 100%;
	background-color: var(--light-grey);
	// background-color: rebeccapurple;
	// max-width: calc(100vw - #{getRem(40)});
	// min-height: 100px;
	// width: calc(100vw - #{getRem(40)});
	// margin-left: calc((((100vw - 100%) / 2) * -1) + #{getRem(20)});

	.wrapper {
		padding: getRem(40) getRem(32);

		h1,
		h2,
		h3,
		h4,
		h5 {
			&:first-of-type {
				margin-top: 0;
			}
		}

		@include breakpoint(desktop) {
			// --max-width-small: #{getRem(1240)};
			// display: grid;
			// grid-template-columns: 2fr 1fr;
			// gap: getRem(40);
		}
	}

	.ck-callout-main {
		p {
			margin-bottom: 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

article .video-block {
	margin: getRem(80) 0;
	width: 100%;
	max-width: var(--content-max-width);
	position: relative;

	.video-container {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;

		// background-color: rebeccapurple;
		&>div,
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.takeaway .files {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
}

figure.media {
	max-width: var(--max-width);
	margin: 0;
}

.content.large {

	&::after,
	&::before {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	img {
		max-width: 100%;
	}
}

.pageComments {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background-color: red;
	pointer-events: none;

	.comment {
		position: absolute;
		width: auto;
		max-width: getRem(44);
		min-height: 50px;
		min-width: 500px;
		height: auto;
		z-index: 5;
		pointer-events: none;
	}

	.circle {
		appearance: none;
		border-radius: 100%;
		width: getRem(50);
		height: getRem(50);
		position: absolute;
		top: getRem(-25);
		left: getRem(-25);
		background-color: var(--red);
		opacity: 0.75;
		z-index: 5;
		pointer-events: all;
		transform-origin: center;
		transform: scale(1);
		transition: all 0.5s md-ease(ease-in-out);
		cursor: pointer;
		border: 2px solid var(--yellow);
		display: flex;
		align-items: center;
		justify-content: center;

		&.resolved {
			background-color: var(--black);
		}

		span.number {
			font-weight: bold;
			font-size: 1.5rem;
			text-align: center;
			color: var(--white);
		}

		&:hover {
			// @include bounce;
			// animation-iteration-count: infinite;
			// transition: all 0.25s md-ease(ease-in-out);
			transform: scale(1.25);
			opacity: 0.95;
		}
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 500px;
		min-height: 200px;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.25s md-ease(ease-in-out), transform 0.25s md-ease(ease-in-out);
		transform: translate3d(0, getRem(50), 0);
		padding: 2rem;
		background-color: var(--black);
		border-radius: getRem(6);
		color: var(--white);
		box-shadow: var(--shadow);
		border-left: 3px solid var(--red);

		button {
			cursor: pointer;
		}

		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: 0;
		}

		.caption {
			margin-top: 0;
			color: var(--light-grey);
		}

		.btn.close {
			position: absolute;
			top: 1rem;
			right: 1rem;
			padding: 0.25em 0.5em;
			margin: 0;
			font-size: 0.75rem;
			// color: white;
			// &::before {
			//     background-color: transparent;
			// }
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 1rem;

			button {
				margin: 0;
			}
		}

		textarea {
			width: 100%;
			min-height: getRem(100);
			padding: 0.75rem;
			border-radius: getRem(6);
			margin-bottom: 1rem;
		}
	}

	.comment.comment-on-left .content {
		left: -500px;
		// right: 0;
		border-left: none;
		border-right: 3px solid var(--red);
	}

	// .comment:hover,
	// .comment:focus,
	// .comment:focus-within
	.comment.show-comment {
		// background-color: blue;
		pointer-events: all;
		z-index: 6;

		.circle {
			// opacity: 0;
			pointer-events: none;
			transform: scale(0.5);
			opacity: 1;
			border-color: var(--red);
		}

		.content {
			transform: translate3d(0, 0, 0);
			opacity: 1;
			pointer-events: all;

			&.focus,
			&.focus-within,
			&:hover {
				pointer-events: all;
			}
		}
	}
}

// article.no-show-comments .pageComments{
//     display: none;
// }

.bottom-section {
	padding-bottom: getRem(104);

	.row {
		display: flex;
	}
}

.file-section {
	width: calc(100% - 312px);
	border-bottom: 1px solid rgba(#2D2D2D, .2);
	padding-bottom: getRem(32);

	@media (max-width: 1024px) {
		width: 100%;
	}

	&-inner {
		background: var(--light-grey);
		border-radius: getRem(8);
		padding: getRem(20) 0;
	}

	h2 {
		display: flex;
		align-items: center;
		@include font(14, 18);
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 getRem(48);

		svg {
			margin-right: getRem(4);
		}
	}

	.files-container {
		padding: 0 getRem(48) getRem(4);
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 8px));
		grid-gap: getRem(16);
		margin-top: getRem(18);

		@media (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}

	.file-item {
		background: var(--white);
		border-radius: getRem(4);
		display: flex;
		padding: getRem(16);

		a {
			max-width: 100%;
			display: inline-block;

			p {
				line-height: 120%;
				margin-bottom: getRem(8);
				word-wrap: break-word;
			}

			&:hover,
			&:focus {
				p {
					text-decoration: underline;
				}
			}
		}

		&-inner {
			width: 100%;
		}

		.file-item-inner {
			&>svg {
				flex-shrink: 0;
				margin-right: getRem(8);
				margin-top: getRem(4);
			}
		}
	}

	.text-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			margin: 0;
		}

		.row {
			justify-content: space-between;
			align-items: center;
		}

		.content-type {
			// margin-top: getRem(10);

			.openLabel {
				display: flex;
				@include font(16, 18);
				align-items: center;

				svg {
					margin-right: getRem(10);
				}
			}

			p {
				@include font(14, 16);

				&:last-child {
					text-decoration: none !important;
				}
			}
		}
	}
}

.comment-reaction-section {
	width: calc(100% - 312px);
	padding-top: getRem(36);

	@media (max-width: 1024px) {
		width: 100%;
	}

	.section-header {
		display: flex;
		justify-content: space-between;

		@media (max-width: 1024px) {
			display: block;
		}
	}

	h2 {
		@include font(20, 28);
		margin: auto 0;
	}

	ul {
		display: grid;
		grid-template-columns: repeat(4, 60px);
		grid-column-gap: getRem(8);
		list-style: none;
		padding: 0;

		li {
			background: var(--light-grey);
			border-radius: getRem(4);
			display: flex;
			align-items: center;
			margin: 0;
			padding: getRem(8);
			position: relative;

			button {
				background: none;
				border: none;
				cursor: pointer;
				display: flex;
				align-items: center;

				span {
					@include font(14, 18);
					font-weight: 700;
					margin: 0 0 0 getRem(6);
				}
			}
		}

		.thumbsUpItem {
			button {
				margin: auto;

				.reaction-overlay {
					width: 100% !important;
					height: 100% !important;
					@include font(2, 2);
					opacity: 0;
					position: absolute !important;
					top: 0;
					left: 0;
					z-index: 101;
				}
			}
		}

		.bookmarkItem {
			&>span {
				margin: auto;
			}

			button {
				span {
					display: none;
				}
			}

			.notBookmarked {
				svg {
					path {
						stroke: var(--black);
						stroke-width: getRem(2);
					}
				}
			}
		}

		.followItem,
		.shareItem {
			&>div {
				margin: auto;
			}

			button {
				background: none !important;
				position: relative;

				span {
					width: 100%;
					height: 100%;
					@include font(2, 2);
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
				}

				svg {
					path {
						fill: var(--black);
						stroke: var(--black);
					}
				}
			}
		}

		.followItem {
			li {
				background: none;
				padding: getRem(16);
			}
		}

		.shareItem {
			.share-list {
				display: inline-block;
				position: absolute;
				top: 100%;
				left: 0;

				li {
					background: none;
				}
			}
		}
	}

	.iconContainer {
		width: getRem(30);
		height: getRem(30);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;

		&.active {
			background: var(--reaction-thumbsUp);
		}
	}

	.reactUp {
		padding: 0;

		svg {
			width: getRem(18);
			height: getRem(18);
		}
	}

	.caseStudyLink {
		margin-top: getRem(16);

		svg {
			margin-right: getRem(8);
		}

		p,
		a {
			@include font(18, 24);
			font-weight: 700;
			margin: 0;
		}

		a {
			color: var(--blue);
		}
	}

	.commentContent {
		margin-top: getRem(18);

		textarea {
			min-height: getRem(75);
		}

		.btn {
			width: 100%;
		}
	}
}

.article,
.bottom-section {
	.share-button-container {
		width: 100%;
		height: 100%;
		padding: 0;

		&>button {
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;

			&:before {
				display: none;
			}

			&:after {
				display: none;
			}

			svg {
				margin: auto;
			}

			span {
				margin: 0 !important;
			}
		}

		.share-list {
			display: inline-block !important;
			position: absolute;
			top: 100%;
			left: 0;

			li {
				background: none;
			}
		}
	}
}

.nextCard {

	@media (max-width: 768px) {
		margin-bottom: getRem(16);
	}

	.content {
		&>div:last-child {
			padding: getRem(18) 0;

			@media (max-width: 768px) {
				padding: getRem(18);
			}
		}
	}
}